import { render, staticRenderFns } from "./ResetStatus.vue?vue&type=template&id=47af4ee2&scoped=true&"
import script from "./ResetStatus.vue?vue&type=script&lang=ts&"
export * from "./ResetStatus.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47af4ee2",
  null
  
)

export default component.exports