
















































































































































































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import store from "@/services/store";
import { loadUserLevels } from "../budget/budgetVariantService";
import {genListOfYears} from "@/modules/budget/budgetCorrectHelper";
import {IEbkItem} from "@/modules/user-management/user-management-int";
import {BDropdown, BIconSearch, BTable} from "bootstrap-vue";
import {
    TransfertGroup,
    ProjectCheckerData,
    FundingSource,
    TransferData,
    IDict,
    applyFilters,
    dict_transfert_group,
    TransferRegion,
    TransferSaveData,
    ProjectFromBank, KbkCode, TransferSources, KbkData,
} from "@/modules/transfert/TransferTypes";
import TransferAddModal from "./TransferAddModal.vue";
import TransferKbkAddModal from "./TransferKbkAddModal.vue";

@Component({
    components: {
        BIconSearch,
        TransferAddModal,
        TransferKbkAddModal,
    },
})
export default class Transfert extends Vue {
    async mounted() {
        await this.getUserLevels();
        await this.loadUserType();
        await this.setUserBudgetLevel();
        await this.loadRegions();
        await this.loadDirection();
        await this.load_cur_year();
        await this.loadTransfers();
    }

    get userUiid() {
        return store.state.user.sub;
    }

    $refs!: {
        transferAddRef: TransferAddModal;
        transferKbkAddRef: TransferKbkAddModal;
        drop: BDropdown;
        myTable: BTable;
    };

    dict_transfer_group = dict_transfert_group();

    @Watch("regionFilter")
    onChangeRegionFilter(value: string, oldValue: string) {
        localStorage[this.lsRegionFilter] = value;
        this.loadTransfers();
        this.selectedProject = null;
    }

    @Watch("transferGroupFilter")
    onChangeTransferGroupFilter(value: string, oldValue: string) {
        localStorage[this.lsTransferGroupFilter] = value;
        this.loadTransfers();
        this.selectedProject = null;
    }

    @Watch("directionFilter")
    onChangedirectionFilter(value: string, oldValue: string) {
        localStorage[this.lsDirectionFilter] = value;
        if (this.selectedDirectionFilter !== "") {
            this.selectedDirectionFilter = value;
        }
    }

    @Watch("cur_year")
    onChangeYearFilter(value: number, oldValue: number) {
        localStorage[this.lsYearFilter] = value;
        this.loadTransfers();
    }

    @Watch('transfertList', { immediate: true })
    onTransferListChange(newList: TransferData[]) {
        this.$nextTick(() => {
            this.$refs.transferAddRef.updateAllTransfersList(newList);
        });
    }

    openFilterByRef(refName: string) {
        const drop: any = this.$refs.drop;
        drop.show(true);
    }

    accessLevel: number = 0

    async getUserLevels() {
        if (this.userUiid) {
            let response: any = [];
            try {
                await loadUserLevels(this.userUiid).then(data => {
                    response = data;
                });
                for (const row of response) {
                    switch (row.modules) {
                        case "003.007":
                            this.accessLevel = row.access_level;
                            break;
                            default:
                                break;
                    }
                }
            } catch (error) {
                this.makeToast("danger", "Ошибка getUserLevels", error instanceof Error ? error.message : String(error));
            }
        }
    }

    get isItemDisabled() {
        const levelAccessToEdit = [2, 3];
        //return false;
        return !levelAccessToEdit.includes(this.accessLevel);
    }

    clickTransfer(item: TransferData, index: number, event: any) {
        if (this.selectedProject === item) {
            this.selectedProject = null; // Снимаем выбор, если кликнули по тому же проекту
        } else {
            if (item.direction){
                this.selectedDirectionFilter = item.direction;
            }
            this.selectedProject = item;
            if (!this.selectedProject.kbk_data.has(this.regionFilter)) {
                this.selectedProject.kbk_data.set(this.regionFilter, [])
            }
            this.checkDeleteConditions(item)
        }
    }

    getRegion(regCode: string) {
        const res = this.regionList.find((value: any) => value.code == regCode);
        if (res) {
            return res;
        } else {
            return {
                name_ru: "Не выбран",
            };
        }
    }

    getFgr(regCode: string) {
        const res = this.dict_transfer_group.find((value: any) => value.code == regCode);
        if (res) {
            return res;
        } else {
            return {
                name_ru: "Не выбран",
            };
        }
    }

    transfer_add() {
        this.$bvModal.show("transfer_add_modal");
    }

    modalSource: TransferSources = {
        funding_source: "01",
        budget_level: "01"
    }

    modalKbk: KbkCode = {abp: 0, ppr: 0, prg: 0};

    @Watch("modalKbk")
    onChangeModalKbk(value: string, oldValue: string) {
    }


    makeToast(variant: string, tostbody: string, title: string) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            autoHideDelay: 4000,
            solid: true,
        });
    }

    hide() {
        (this.$refs.drop as BDropdown).hide(true);
    }

    cur_year = new Date().getFullYear();

    load_cur_year() {
        if (localStorage[this.lsYearFilter]) {
            this.cur_year = Number(localStorage[this.lsYearFilter]);
        } else {
            this.cur_year = new Date().getFullYear();
        }
    }

    get listOfYears() {
        return genListOfYears();
    }

    regionList: IDict[] = [];
    regionBase: IDict[] = [];
    regionFilter = "";
    lsRegionFilter = "transfert_region";
    transferGroupFilter = null;
    lsTransferGroupFilter = "transfert_group";
    lsDirectionFilter = "transfert_Direction";
    lsYearFilter = "transfert_year";
    userBudgetLevel: string = "";
    isLead: boolean = false;

    private async setUserBudgetLevel() {
        const response = await fetch("/api-py/get-user-budget-level-transfert/" + this.userUiid);
        this.userBudgetLevel = await response.json()
        return this.userBudgetLevel
    }
    
    private async loadUserType() {
        const response = await fetch("/api-py/get-user-budget-level-transfert-if-lead/" + this.userUiid);
        this.isLead = await response.json()
    }

    private async loadRegions() {
        const code: string = this.$store.state._instanceCode;
        let region = {
            obl: "45",
        };
        await fetch("/api-py/get-budget-obl/" + code)
            .then((response) => response.json())
            .then((json) => {
                region = json;
            });
        try {
            const response = await fetch(
                "/api-py/get-user-regions-by-obl/" +
                region.obl +
                "/" +
                this.$store.getters.user_uuid
            );
            this.regionBase = (await response.json()) as IDict[];
            this.regionList = [...this.regionBase];
            if (localStorage[this.lsRegionFilter]) {
                this.regionFilter = localStorage[this.lsRegionFilter];
            } else if (this.regionList[0]) {
                this.regionFilter = this.regionList[0].code;
            }
        } catch (error) {
            this.makeToast(
                "danger",
                "Ошибка загрузки регионов",
                error instanceof Error ? error.message : String(error)
            );
        }
    }

    funcGrBase: any = [];
    directionList: any[] = [];
    directionFilter = "";

    private async loadDirection() {
        try {
            const response = await fetch("/api-py/get-dict-func-childs-by-parent/0");
            this.funcGrBase = (await response.json()) as IEbkItem;
            this.directionList = [...this.funcGrBase];
            if (localStorage[this.lsDirectionFilter]) {
                this.directionFilter = localStorage[this.lsDirectionFilter];
            } else if (this.directionList[0]) {
                this.directionFilter = this.directionList[0].code;
            }
        } catch (error) {
            this.makeToast(
                "danger",
                "Ошибка загрузки функциональных групп",
                error instanceof Error ? error.message : String(error)
            );
        }
    }

    register_of_expense_items_table_field = [
        {
            key: "transfer_group",
            label: "Группа",
            sortable: true,
            thStyle: {width: "15px"},
        },
        {
            key: "expense_code",
            label: "Код",
            sortable: true,
            sortByFormatted: true,
            thStyle: {width: "15px"},
            formatter: (value: any, key: any, item: TransferData) =>
                this.formatCode(value, key, item),
            sortCompare: (a: any, b: any, rowA: TransferData, rowB: TransferData) => {
                const codeA = this.formatCode(a, "expense_code", rowA);
                const codeB = this.formatCode(b, "expense_code", rowB);
                return codeA.localeCompare(codeB, undefined, { numeric: true, sensitivity: "base" });
            }
        },
        {
            key: "name",
            label: "Наименование",
            sortable: true,
            sortByFormatted: true,
            thStyle: {width: "200px"},
            formatter: (value: any, key: any, item: TransferData) =>
                this.formatName(value, key, item),
        },
        {
            key: "direction",
            label: "Направление",
            sortable: true,
            thStyle: {width: "200px"},
            formatter: (value: any, key: any, item: TransferData) =>
                this.formatDirection(value, key, item),
        },
        {
            key: "period",
            label: "Период реализации",
            sortable: true,
            thStyle: {width: "5px"},
            formatter: (value: any, key: any, item: TransferData) =>
                this.formatPeriod(value, key, item),
        },
        {
            key: "status",
            label: "Статус",
            thStyle: {width: "100px"},
            formatter: (value: any, key: any, item: TransferData) =>
                this.formatStatus(value, key, item),
        },
        { 
            key: 'actions', 
            label: 'Действия',
            thStyle: {width: "1px"}, 
        },
    ];

    formatStatus(value: any, key: string, item: TransferData): string {
        if (item.project_from_bank?.is_archive == true) return "Архивный";
        if (item) {
            if (item.kbk_data.has(this.regionFilter)) {
                const kbk_data = item.kbk_data.get(this.regionFilter)
                if (kbk_data) {
                    for (const kbkDatum of kbk_data) {
                        if ((kbkDatum.kbk_code.abp != 0) && (kbkDatum.kbk_code.prg != 0) && (kbkDatum.kbk_code.ppr != 0)) {
                            return "Заполнено";
                        }
                    }
                } else {
                    return "Нет КБК!"
                }
            } else {
                return "Нет КБК."
            }
        } else {
            return "Не заполнено!";
        }
        return "....."
    }

    formatName(value: any, key: string, item: TransferData): string {
        if (!item?.project_from_bank?.code) {
            return "Нет наименования проекта";
        }
        return (
            this.modalProjectList.find(
                (value1) => value1.code === item.project_from_bank.code
            )?.name_ru || "Нет наименования проекта"
        );
    }

    formatCode(value: any, key: string, item: TransferData): string {
        if (!item?.project_from_bank?.code) {
            return "Нет кода в проектах";
        }
        return (
            this.modalProjectList.find(
                (value1) => value1.code === item.project_from_bank.code
            )?.code || "Нет кода в проектах"
        );
    }

    formatPeriod(value: any, key: string, item: TransferData): string {
        const project = this.modalProjectList.find(
            (value1) => value1.code === item.project_from_bank.code
        );
        return project ? `${project.begin_date} - ${project.end_date}` : "Нет кода в проектах";
    }

    formatDirection(value: any, key: string, item: TransferData): string {
        return (
            this.directionList.find((value1) => value1.gr == value)?.name_ru ||
            "Нет кода направления"
        );
    }

    clearedRows: TransferSaveData[] = [];
    deletedRows: TransferSaveData[] = [];

    sources_of_financing_fields = [
        {
            key: "code_funding_level",
            label: "Уровень финансирования",
            formatter: (value: any, key: any, item: TransferData) =>
                this.formaterFundingLevel(value, key, item),
        },
        {
            key: "code_1_level",
            label: "КБК (Нац. фонд)",
            thStyle: {width: "30px"},
        },
        {key: "code_2_level", label: "КБК (РБ)", thStyle: {width: "30px"}},
        {key: "code_3_level", label: "КБК (ОБ)", thStyle: {width: "30px"}},
        {key: "code_4_level", label: "КБК (Район)", thStyle: {width: "30px"}},
        {key: "code_5_level", label: "КБК (МСУ)", thStyle: {width: "30px"}},
    ];

    get filteredSourcesFields() {
        const regionLevel = this.get_region_level(this.regionFilter);

        return this.sources_of_financing_fields.map(field => {
            const allowedKeys = {
                "03": ["code_1_level", "code_2_level", "code_3_level"], // Область
                "04": ["code_4_level"], // Район
                "05": ["code_5_level"] // МСУ
            };

            return {
                ...field,
                disabled: !allowedKeys[regionLevel]?.includes(field.key),
            };
        });
    }

    isFieldEditable(level: string, funding_source: string): boolean {
        if (!this.regionFilter.endsWith("0101") && ["01", "02"].includes(level)) {
            return false;
        }
        const regionLevel = this.get_region_level(this.regionFilter);
        if (regionLevel === "03") {
            return (
                (level === "01" && funding_source === "01") ||
                (level === "02" && funding_source === "02") ||
                (level === "03" && ["01", "02", "03"].includes(funding_source))
            );
        } 
        if (regionLevel === "04") {
            return level === "04" && funding_source !== "05";
        }
        if (regionLevel === "05") {
            return level === "05";
        }

        return false;
    }

    formaterFundingLevel(value: any, key: string, item: any): string {
        const fund = this.funding_level.find((fl) => fl.code == value);
        if (fund) return value + " - " + fund.name_ru;
        return value + " test" + key;
    }

    funding_level = [
        {code: "01", name_ru: "Национальный фонд"},
        {code: "02", name_ru: "Республиканский"},
        {code: "03", name_ru: "Областной"},
        {code: "04", name_ru: "Районный"},
        {code: "05", name_ru: "МСУ"},
    ];

    get funding_table_items(): FundingSource[] {
        const result: FundingSource[] = [];
        for (const fl of this.funding_level) {
            const item: FundingSource = {
                code_funding_level: fl.code,
                code_1_level: "",
                code_2_level: "",
                code_3_level: "",
                code_4_level: "",
                code_5_level: "",
            };
            result.push(item);
        }
        return result;
    }

    modalProjectListUpdated(projects: ProjectFromBank[]) {
        this.modalProjectList = projects;
    }

    modalProjectList: ProjectFromBank[] = [];

    get regionForTransferList(): TransferRegion[] {
        const result: TransferRegion[] = [];
        for (const region of this.regionsForFunding) {
            const tr_region: TransferRegion = {
                checked: region.code == this.regionFilter,
                region: region,
                disabled: false
            };
            let list_of_region: string[] = [];
            const projectInRegion = this.transfertList.some(project => {
                if (project.project_from_bank?.code !== this.selectedProject?.project_from_bank?.code) {
                    return false;
                }
                const kbkEntries = project.kbk_data.get(region.code);
                return kbkEntries && kbkEntries.length > 0;
            });
            if (projectInRegion) {
                tr_region.checked = true;
            }
            if (this.selectedProject) {
                list_of_region = Array.from(this.selectedProject.kbk_data.keys());
            }
            if (list_of_region?.includes(tr_region.region.code)) {
                tr_region.checked = true;
                if (this.selectedProject) {
                    const kbk_entries = this.selectedProject.kbk_data.get(tr_region.region.code);
                    if (kbk_entries?.some(kbk => Object.values(kbk.kbk_code).some(val => val !== 0))) {
                        tr_region.disabled = true;
                    }
                }
            }
            if (this.regionFilter.endsWith("0101") && tr_region.region.code.match(/^\d{4}01$/)) {
                const regionPrefix = tr_region.region.code.slice(0, 4); // XXYY
                if (list_of_region.some(kbk_code => kbk_code.startsWith(regionPrefix) && !kbk_code.endsWith("01"))) {
                    tr_region.disabled = true;
                }
            }
            result.push(tr_region);
        }

        return result;
    }

    updateRegionSelection(projectCode: string, regionCode: string, isChecked: boolean) {
        const project = this.transfertList.find(
        p => p.project_from_bank.code === projectCode && p.kbk_data.has(this.regionFilter)
        );
        if (!project) return;

        if (isChecked) {
            if (!project.kbk_data.has(regionCode)) {
                project.kbk_data.set(regionCode, [{
                        kbk_code: { abp: 0, prg: 0, ppr: 0 },
                        transfer_sources: { funding_source: this.get_transfer_funding_source(project), budget_level: this.get_region_level(regionCode) }
                    }]);
            }
            this.deletedRows = this.deletedRows.filter(row => row.region !== regionCode);
        } else {
            const kbkEntry = project.kbk_data.get(regionCode);
            if (!kbkEntry) return;
            const hasLower = this.hasLowerLevels(project, {
                expense_code: project.project_from_bank.code,
                direction: project.direction,
                transfer_group: project.transfer_group ?? null,
                funding_source: this.get_transfer_funding_source(project),
                budget_level: this.get_region_level(regionCode),
                region: regionCode,
                year: this.cur_year,
                user_id: this.$store.getters.user_uuid
            });

            if (hasLower) {
                this.makeToast("danger", "Ошибка", "Присутствует распределение на нижестоящем уровне, отменить выбор региона невозможно.");
                return;
            }
            if (!this.deletedRows.some(row => row.region === regionCode && row.expense_code === projectCode)) {
                this.deletedRows.push(...kbkEntry.map(kbk => ({
                    expense_code: project.project_from_bank.code,
                    direction: project.direction,
                    transfer_group: project.transfer_group,
                    funding_source: kbk.transfer_sources.funding_source,
                    budget_level: kbk.transfer_sources.budget_level,
                    abp: kbk.kbk_code.abp,
                    prg: kbk.kbk_code.prg ?? null,
                    ppr: kbk.kbk_code.ppr ?? null,
                    region: regionCode,
                    year: this.cur_year,
                    user_id: this.$store.getters.user_uuid
                })));
            }
            project.kbk_data.delete(regionCode);
        }
        this.$forceUpdate();
    }

    get regionsForFunding() {
        const regionCode = this.regionFilter;

        if (regionCode.endsWith("0101")) {
            // Если выбранная область (XX0101), то выбираем область + районы этой области (XXYY01, где YY ≠ 01 и 00)
            const regionPrefix = regionCode.substring(0, 2); // XX
            return this.regionList.filter(
                (value) =>
                    value.code.startsWith(regionPrefix) &&
                    (value.code.endsWith("0101") ||
                        (value.code.endsWith("01") &&
                            !value.code.endsWith("0101") &&
                            !value.code.endsWith("00")))
            );
        }

        const regionPart = regionCode.substring(0, 4); // XXYY

        if (regionCode.endsWith("01")) {
            // Если выбран район (XXYY01, где YY ≠ 01), выбираем район и его МСУ (XXYYZZ, где ZZ ≠ 01 и 00)
            return this.regionList.filter(
                (value) =>
                    value.code.startsWith(regionPart) &&
                    (value.code.endsWith("01") ||
                        (!value.code.endsWith("00") && !value.code.endsWith("01")))
            );
        }

        // Если выбран МСУ (XXYYZZ, где YY ≠ 01 и ZZ ≠ 01), то возвращаем только выбранный регион
        return this.regionList.filter((value) => value.code === regionCode);
    }

    addSelectedProjects(selectList: ProjectCheckerData[]) {
        const select_project = selectList.filter((value) => value.checked);
        this.selectedProject = null;
        this.selectedDirectionFilter = "";
        for (const mtd of select_project) {
            const kbk_data = new Map<string, KbkData[]>();
            const projectRegions = this.regionForTransferList;
            for (const transferRegion of projectRegions) {
                if (transferRegion.checked === true) {
                    const kbk_code: KbkCode = {
                        abp: 0,
                        prg: 0,
                        ppr: 0,
                    };
                    const kbk_source: TransferSources = {
                        funding_source: this.get_transfer_funding_source(this.selectedProject),
                        budget_level: this.get_region_level(transferRegion.region.code)
                    };
                    const existingKbkData = kbk_data.get(transferRegion.region.code) || [];
                    existingKbkData.push({
                        kbk_code: kbk_code,
                        transfer_sources: kbk_source
                    });
                    kbk_data.set(transferRegion.region.code, existingKbkData);
                }
            }
            const trn: TransferData = {
                checked: mtd.checked,
                transfer_group: mtd.transfer_group,
                transfer_group_list: mtd.transfer_group_list,
                direction: "", 
                year: this.cur_year,
                project_from_bank: mtd.project_from_bank,
                kbk_data: kbk_data
            };
            const foundItem = this.transfertList.find(
                (value) =>
                    value.project_from_bank.code === mtd.project_from_bank.code &&
                    value.project_from_bank.budget_region === this.regionFilter && Array.from(value.kbk_data.keys()).includes(this.regionFilter)
            );
            if (!foundItem) {
                this.transfertList.push(trn);
            }
        }
    }

    transfertList: TransferData[] = [];

    get transfertListFilter(): TransferData[] {
        const result = this.transfertList.filter(value => Array.from(value.kbk_data.keys()).includes(this.regionFilter));
            if (this.transferGroupFilter) {
            return result.filter(value => value.transfer_group === this.transferGroupFilter);
        }
        return result
    }

    get_transfer_funding_source(transfer: TransferData | null) {
        let result = "05";
        if (transfer != null) {
            const kbk_data = transfer.kbk_data;
            if (kbk_data) {
                for (const key of kbk_data.keys()) {
                    const trans_source = kbk_data.get(key);
                    if (trans_source) {
                        for (const kbkDatum of trans_source) {
                            if (kbkDatum.transfer_sources.funding_source < result) {
                                result = kbkDatum.transfer_sources.funding_source
                            }
                        }
                    }
                }
            }
        }
        return result;
    }

    async saveTransfert() {
        const listToSave = [];
        const userId = this.$store.getters.user_uuid;
        if (this.deletedRows.length > 0) {
            const confirm = await this.$bvModal.msgBoxConfirm(
                `Вы подтверждаете изменение Региона получателя?`, {
                    title: "Подтверждение",
                    okVariant: "danger",
                    okTitle: "ДА",
                    cancelTitle: "НЕТ",
                }
            );

            if (!confirm) return;
            await this.deleteKBKFromDB(this.deletedRows);
            this.deletedRows = [];
        }
        if (this.clearedRows.length > 0) {
            await this.deleteKBKFromDB(this.clearedRows);
            this.clearedRows = [];
        }
        const filteredProjects = this.transfertList.filter(project =>
            project.kbk_data?.has(this.regionFilter)
        );
        for (const transferItem of filteredProjects) {
            for (const transferRegion of this.regionForTransferList.filter(r => r.region.code !== this.regionFilter)) {
                if (!transferItem.kbk_data.has(transferRegion.region.code)) continue;
                let kbkEntries = transferItem.kbk_data.get(transferRegion.region.code) || [];
                if (kbkEntries.length === 0) {
                    kbkEntries = [{
                        kbk_code: { abp: 0, prg: 0, ppr: 0 },
                        transfer_sources: { funding_source: this.get_transfer_funding_source(transferItem), budget_level: this.get_region_level(transferRegion.region.code) }
                    }];
                }

                for (const kbk of kbkEntries) {
                    listToSave.push({
                        expense_code: transferItem.project_from_bank?.code,
                        direction: transferItem.direction,
                        transfer_group: transferItem.transfer_group,
                        funding_source: kbk.transfer_sources?.funding_source ?? this.get_transfer_funding_source(transferItem),
                        budget_level: kbk.transfer_sources?.budget_level ?? this.get_region_level(transferRegion.region.code),
                        abp: kbk.kbk_code?.abp ?? 0,
                        prg: kbk.kbk_code?.prg ?? 0,
                        ppr: kbk.kbk_code?.ppr ?? 0,
                        region: transferRegion.region.code,
                        year: this.cur_year,
                        user_id: userId
                    });
                }
            }
            if (!transferItem.direction) {
                this.$bvToast.toast(`Не выбрано Направление для проекта ${transferItem.project_from_bank?.code}`, {
                    title: "Ошибка",
                    variant: "danger",
                    solid: true
                });
                continue;
            }
            const kbkData = transferItem.kbk_data.get(this.regionFilter) || [];
            const nonZeroKBK = kbkData.filter(kbk => kbk.kbk_code.abp !== 0 || kbk.kbk_code.prg !== 0);
            const zeroKBK = kbkData.filter(kbk => kbk.kbk_code.abp === 0 && kbk.kbk_code.prg === 0);
            if (nonZeroKBK.length > 0) {
                await this.deleteKBKFromDB(zeroKBK.map(kbk => ({
                    expense_code: transferItem.project_from_bank?.code,
                    direction: transferItem.direction,
                    transfer_group: transferItem.transfer_group,
                    funding_source: kbk.transfer_sources.funding_source,
                    budget_level: kbk.transfer_sources.budget_level,
                    abp: kbk.kbk_code.abp,
                    prg: kbk.kbk_code.prg ?? null,
                    ppr: kbk.kbk_code.ppr ?? null,
                    region: this.regionFilter,
                    year: this.cur_year,
                    user_id: userId
                })));
                transferItem.kbk_data.set(this.regionFilter, nonZeroKBK);
                this.$forceUpdate();
            }
            const kbkToSave = nonZeroKBK.length > 0 ? nonZeroKBK : zeroKBK;
            for (const kbk of kbkToSave) {
                listToSave.push({
                    expense_code: transferItem.project_from_bank?.code,
                    direction: transferItem.direction,
                    transfer_group: transferItem.transfer_group,
                    funding_source: kbk.transfer_sources.funding_source,
                    budget_level: kbk.transfer_sources.budget_level,
                    abp: kbk.kbk_code.abp,
                    prg: kbk.kbk_code.prg ?? null,
                    ppr: kbk.kbk_code.ppr ?? null,
                    region: this.regionFilter,
                    year: this.cur_year,
                    user_id: userId
                });
            }
        }
        if (listToSave.length > 0) {
            await this.processSave(listToSave);
        } else {
            const confirm = await this.$bvModal.msgBoxConfirm(
                "Проект удалится - отсутствуют записи по КБК. Вы уверены?", {
                    title: "Подтверждение",
                    okVariant: "danger",
                    okTitle: "ДА",
                    cancelTitle: "НЕТ",
                }
            );
            if (confirm) {
                await this.processSave([]);
            }
        }
        this.$forceUpdate();
    }

    async processSave(listToSave: TransferSaveData[]) {
        let success = true;
        for (const saveData of listToSave) {
            const response = await fetch("/api-py/save-transfer", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(saveData),
            });
            const result = await response.json();
            if (!(response.status === 200 && result.status === "success")) {
                success = false;
            }
        }
        if (success) {
            this.makeToast("success", "Все данные успешно сохранены", "Сохранение");
        } else {
            this.makeToast("danger", "Некоторые данные не удалось сохранить", "Ошибка");
        }
        this.$forceUpdate();
    }

    hasLowerLevels(project: TransferData, kbk: TransferSaveData): boolean {
        const isRegion = kbk.budget_level === "01"; // Область
        const isDistrict = kbk.budget_level === "04"; // Район

            for (const [kbkRegion, kbkEntries] of project.kbk_data.entries()) {
                for (const entry of kbkEntries as KbkData[]) {
                    if (isRegion && entry.transfer_sources.budget_level === "04" && kbkRegion.startsWith(kbk.region.slice(0, 2))) {
                        return true; // У района есть записи
                    }
                    if (isDistrict && entry.transfer_sources.budget_level === "05" && kbkRegion.startsWith(kbk.region)) {
                        return true; // У МСУ есть записи
                    }
                }
            }
        return false;
    }

    async deleteKBKFromDB(deletedRows: TransferSaveData[]) {
        if (deletedRows.length === 0) return;
        const projectCodes = [...new Set(deletedRows.map(kbk => kbk.expense_code))];
        const projectsToUpdate = this.transfertList.filter(p =>
            projectCodes.includes(p.project_from_bank.code)
        );

        const deleteRequests = deletedRows.map(kbk => {
            const project = projectsToUpdate.find(p => p.project_from_bank.code === kbk.expense_code);
            if (!project) return null;
            const hasLower = this.hasLowerLevels(project, kbk);
            return {
                expense_code: kbk.expense_code,
                direction: project.direction,
                transfer_group: project.transfer_group,
                funding_source: kbk.funding_source,
                budget_level: kbk.budget_level,
                abp: hasLower ? null : kbk.abp,
                prg: hasLower ? null : kbk.prg,
                ppr: hasLower ? null : kbk.ppr,
                region: kbk.region,
                year: kbk.year,
                user_id: this.$store.getters.user_uuid,
            };
        }).filter(Boolean);
        if (deleteRequests.length === 0) return;
        await fetch("/api-py/delete-several-transfers", {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(deleteRequests),
        });
        for (const kbk of deletedRows) {
            const project = projectsToUpdate.find(p => p.project_from_bank.code === kbk.expense_code);
            if (!project) continue;

            const projectData = project.kbk_data.get(kbk.region);
            if (projectData) {
                project.kbk_data.set(kbk.region, projectData.filter(entry =>
                    entry.kbk_code.abp !== kbk.abp ||
                    entry.kbk_code.prg !== kbk.prg ||
                    entry.kbk_code.ppr !== kbk.ppr
                ));
            }
        }
        this.$forceUpdate();
    }

    isLowerLevelKBKEmpty(expenseCode: string, region: string): boolean {
        const project = this.transfertList.find(p => p.project_from_bank.code === expenseCode);
        if (!project) return true;

        const kbkEntries = project.kbk_data.get(region) || [];
        return kbkEntries.every(kbk => {
            if (kbk.transfer_sources.budget_level === "01" && this.regionFilter.endsWith("0101")) return true; // Область не учитывает сама себя
            if (kbk.transfer_sources.budget_level === "04" && this.regionFilter.endsWith("01")) return true;   // Район не учитывает сам себя
            return !kbk.kbk_code.abp && !kbk.kbk_code.prg && !kbk.kbk_code.ppr;
        });
    }

    async deleteTransfert(transfert: TransferData) {
        const checkResult = this.checkDeleteConditions(transfert);
        if (checkResult.disabled) {
            this.makeToast("danger", "Ошибка", checkResult.message);
            return;
        }

        const confirm = await this.$bvModal.msgBoxConfirm(checkResult.message, {
            title: "Подтверждение",
            okVariant: checkResult.color === "red" ? "danger" : "warning",
            okTitle: "Удалить",
            cancelTitle: "Отменить",
        });

        if (!confirm) return;
        await this.delete(transfert);
    }

    async deleteWithLowerEmptyKBK(transfert: TransferData, lowerRegions: string[]) {
        const expenseCode = transfert.project_from_bank.code;
        await this.delete(transfert);
        const deleteRequests = lowerRegions
            .filter(region => this.isLowerLevelKBKEmpty(expenseCode, region))
            .map(region => ({
                expense_code: expenseCode,
                direction: transfert.direction,
                transfer_group: transfert.transfer_group,
                region: region,
                year: this.cur_year,
                user_id: this.$store.getters.user_uuid,
            }));
        if (deleteRequests.length > 0) {
            await fetch("/api-py/delete-several-transfers", {
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(deleteRequests),
            });
            this.transfertList = this.transfertList.map(project => {
                if (project.project_from_bank.code === expenseCode) {
                    deleteRequests.forEach(req => project.kbk_data.delete(req.region));
                }
                return project;
            }).filter(project => project.kbk_data.size > 0);
        }
    }

    async delete(transfert: TransferData) {
        const deleteData = {
            expense_code: transfert.project_from_bank.code,
            direction: transfert.direction,
            region: this.regionFilter,
            year: this.cur_year,
            user_id: this.$store.getters.user_uuid,
        };
        const response = await fetch("/api-py/delete-transfer", {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(deleteData)
        });
        const result = await response.json();
        if (response.status === 200 && result.status === "success") {
            this.makeToast("success", "Удалено", "Проект удалён");
            this.transfertList = this.transfertList.filter(p => p !== transfert);
        } else {
            this.makeToast("danger", "Ошибка", "Ошибка удаления");
        }
        this.$forceUpdate();
    }

    checkDeleteConditions(project: TransferData) {
        const isRegion = this.regionFilter.endsWith("0101");
        const isDistrict = !isRegion && this.regionFilter.endsWith("01");
        const isLocalGov = !isRegion && !isDistrict;

        let hasLowerDistribution = false;
        let hasUpperDistribution = false;

        for (const [kbkRegion, kbkEntries] of project.kbk_data.entries()) {
            for (const kbkEntry of kbkEntries) {
                const budgetLevel = kbkEntry.transfer_sources.budget_level;

                // Проверка на нижестоящий уровень (район → МСУ, область → район)
                if (isRegion && ["04", "05"].includes(budgetLevel)) {
                    hasLowerDistribution = true;
                }
                if (isDistrict && budgetLevel === "05") {
                    hasLowerDistribution = true;
                }
                if (isDistrict && ["01", "02", "03"].includes(budgetLevel)) {
                    hasUpperDistribution = true;
                }
                if (isLocalGov && budgetLevel === "04") {
                    hasUpperDistribution = true;
                }
            }
        }
        if (isRegion) {
            if (hasLowerDistribution) {
                return {
                    disabled: true,
                    color: "gray",
                    message: "Присутствует распределение на нижестоящем уровне, проект удалить невозможно.",
                };
            }
            return { disabled: false, color: "red", message: "Вы уверены, что хотите удалить проект?" };
        }

        if (isDistrict) {
            if (hasUpperDistribution) {
                return {
                    disabled: true,
                    color: "gray",
                    message: "Проект можно удалить только на вышестоящем уровне.",
                };
            }
            if (hasLowerDistribution) {
                return {
                    disabled: true,
                    color: "gray",
                    message: "Присутствует распределение на нижестоящем уровне, проект удалить невозможно.",
                };
            }
            return { disabled: false, color: "red", message: "Вы уверены, что хотите удалить проект?" };
        }

        if (isLocalGov) {
            if (hasUpperDistribution) {
                return {
                    disabled: true,
                    color: "gray",
                    message: "Проект можно удалить только на вышестоящем уровне.",
                };
            }
            return { disabled: false, color: "red", message: "Вы уверены, что хотите удалить проект?" };
        }

        return { disabled: true, color: "gray", message: "Ошибка проверки" };
    }

    getLowerRegions(transfert: TransferData) {
        const lowerRegions = new Set<string>();
        const currentRegion = this.regionFilter;

        for (const kbkRegion of transfert.kbk_data.keys()) {
            if (currentRegion.endsWith("0101")) {
                if (kbkRegion.startsWith(currentRegion.slice(0, 2)) && kbkRegion.endsWith("01") && kbkRegion !== currentRegion) {
                    lowerRegions.add(kbkRegion);
                }
            } else if (currentRegion.endsWith("01")) {
                if (kbkRegion.startsWith(currentRegion.slice(0, 4)) && !kbkRegion.endsWith("01")) {
                    lowerRegions.add(kbkRegion);
                }
            }
        }
        return Array.from(lowerRegions);
    }

    get selectedGroupCaption() {
        if (this.selectedProject != null) {
            let caption = this.selectedProject?.transfer_group;
            if (caption != "") {
                caption =
                    caption +
                    "-" +
                    this.dict_transfer_group.find((value) => value.code == caption)
                        ?.name_ru;
            }
            return caption;
        } else {
            return "";
        }
    }

    selectedProject: TransferData | null = null;

    get selectedProjectCaption() {
        if (this.selectedProject != null) {
            return (
                this.selectedProject?.project_from_bank.code +
                "-" +
                this.selectedProject?.project_from_bank.name_ru
            );
        } else {
            return "";
        }
    }

    selectedDirectionFilter = "";

    @Watch("selectedDirectionFilter")
    onSelectedDirectionFilterChange(new_value: string, old_value: string) {
        if (this.selectedProject != null) {
            this.selectedProject.direction = new_value;
        }
    }

    private async loadTransfers() {
        try {
            const response = await fetch(`/api-py/get-transfers/${this.cur_year}`);
            const transferBase = (await response.json()) as TransferSaveData[];
            this.transfertList = [];
            for (const trd of transferBase) {
                const kbk_code: KbkCode = {
                    abp: trd.abp || 0,
                    prg: trd.prg || 0,
                    ppr: trd.ppr || 0
                }
                const kbk_source: TransferSources = {
                    budget_level: trd.budget_level || "03",
                    funding_source: trd.funding_source || "03",
                }
                const kbk_data = new Map<string, KbkData[]>();
                kbk_data.set(trd.region, [{
                    kbk_code: kbk_code,
                    transfer_sources: kbk_source
                }]);
                const td: TransferData = {
                    checked: false,
                    transfer_group: trd.transfer_group ?? "",
                    direction: trd.direction ?? "",
                    year: trd.year,
                    project_from_bank:
                        this.modalProjectList.find(
                            (value) => value.code == trd.expense_code
                        ) ?? this.modalProjectList[0],
                    transfer_group_list: [],
                    kbk_data: kbk_data,
                };

                const findTransfert = this.transfertList.find(value => value.project_from_bank == td.project_from_bank && value.transfer_group == td.transfer_group);
                if (findTransfert) {
                    const kbk_data = findTransfert.kbk_data.get(trd.region);
                    if (kbk_data) {
                        kbk_data.push({
                            kbk_code: kbk_code,
                            transfer_sources: kbk_source
                        })
                    } else {
                        findTransfert.kbk_data.set(trd.region, [{
                            kbk_code: kbk_code,
                            transfer_sources: kbk_source
                        }])
                    }
                } else {
                    this.transfertList.push(td);
                }
            }
        } catch (error) {
            this.makeToast(
                "danger",
                "Ошибка загрузки трансфертов",
                error instanceof Error ? error.message : String(error)
            );
        }
    }

    region_kbk_data(region: string) {
        let result = "";
        if (this.selectedProject != null) {
            const kbk_data = this.selectedProject.kbk_data.get(region)
            if (kbk_data?.[0]?.kbk_code) {
                result = `${kbk_data[0].kbk_code.abp}.${kbk_data[0].kbk_code.prg}.${kbk_data[0].kbk_code.ppr ?? '000'}`;
            }
        }
        return result
    }

    transfer_kbk_add(budget_level: string, funding_source: string) {
        this.modalSource = { ...this.modalSource, funding_source, budget_level };
        const kbk_code = this.findKbkObject(budget_level, funding_source, this.regionFilter)?.kbk_code
        if (kbk_code) {
            this.modalKbk = {...kbk_code}
        } else {
            this.modalKbk = {abp: 0, prg: 0, ppr: 0};
        }
        this.$bvModal.show("transfer_kbk_add_modal");
    }


    addKbk(kbk: KbkCode) {
        const find_kbk = this.findKbkObject(this.modalSource.budget_level, this.modalSource.funding_source, this.regionFilter);
        if (find_kbk) {
            this.$set(find_kbk, "kbk_code", kbk);
        } else {
            const kbk_data: KbkData = {
                kbk_code: {...kbk},
                transfer_sources: {...this.modalSource}
            };
            let kbk_list = this.selectedProject?.kbk_data.get(this.regionFilter);
            if (kbk_list) {
                kbk_list.push(kbk_data)
                if (!kbk_list) {
                    kbk_list = [];
                    this.selectedProject?.kbk_data.set(this.regionFilter, kbk_list);
                }
                kbk_list.push(kbk_data);
            }
        }
        this.$refs.myTable.refresh(); // Принудительно обновляем таблицу
    }
    
    
    clearKBK() {
        if (!this.selectedProject) {
            return;
        }
        const kbkData = this.selectedProject?.kbk_data.get(this.regionFilter) || [];
        const hasNonZeroKBK = kbkData.some(kbk => kbk.kbk_code.abp !== 0 || kbk.kbk_code.prg !== 0 || kbk.kbk_code.ppr !== 0);
            if (kbkData.length === 1 && !hasNonZeroKBK) {
                return;
            }
        this.clearedRows.push(...kbkData.map(kbk => ({
            expense_code: this.selectedProject?.project_from_bank.code,
            direction: this.selectedProject?.direction,
            transfer_group: this.selectedProject?.transfer_group,
            funding_source: kbk.transfer_sources.funding_source,
            budget_level: kbk.transfer_sources.budget_level,
            abp: kbk.kbk_code.abp,
            prg: kbk.kbk_code.prg ?? null,
            ppr: kbk.kbk_code.ppr ?? null,
            region: this.regionFilter,
            year: this.cur_year,
            user_id: this.$store.getters.user_uuid
        })));
        this.selectedProject.kbk_data.set(this.regionFilter, [
        {
            kbk_code: { abp: 0, prg: 0, ppr: 0 },
            transfer_sources: {
                funding_source: this.get_region_level(this.regionFilter),
                budget_level: this.get_region_level(this.regionFilter)
            }
        }
    ]);
        this.funding_level = [...this.funding_level];
        this.$forceUpdate();
    }

    get_region_level(region_code: string) {
        if (region_code.endsWith("0101")) return "03";
        if (region_code.endsWith("01")) return "04"
        return "05"
    }

    is_region_parent(child_code: string, parent_code: string): boolean {
        const ch_level = this.get_region_level(child_code);
        const par_level = this.get_region_level(parent_code);

        if (ch_level === '05' && par_level === '04') {
            return child_code.substring(0, 4) === parent_code.substring(0, 4);
        }

        const higherLevels = ["03", "02", "01"];
        if (["05", "04", "03", "02"].includes(ch_level) && higherLevels.includes(par_level)) {
            return child_code.substring(0, 2) === parent_code.substring(0, 2);
        }

        return false;
    }

    findKbkObject(budget_level: string, funding_source: string, regionKey: string): KbkData | null {
        const self_level = this.get_region_level(regionKey);
        const kbk_map = this.selectedProject?.kbk_data;
        if (!kbk_map) return null;

        for (const key of kbk_map.keys()) {
            const key_level = this.get_region_level(key);
            const isParent = key_level < self_level && this.is_region_parent(regionKey, key);
            const isSameLevel = key_level === self_level && key === regionKey;

            if (!isParent && !isSameLevel) continue;
            const kbk_array = kbk_map.get(key);
            if (!kbk_array || kbk_array.length === 0) continue;

            const kbk = kbk_array.find(
                value =>
                    value.transfer_sources.funding_source === funding_source &&
                    value.transfer_sources.budget_level === budget_level
            );
            if (kbk) {
                return kbk;
            }
        }
        return null;
    }

    // Второй метод: преобразует объект в строку
    kbkToString(kbk: KbkData): string {
        if (!kbk) return "...";
        return [
            kbk.kbk_code.abp,
            kbk.kbk_code.prg,
            kbk.kbk_code.ppr ?? '000'
        ].map(part => String(part).padStart(3, '0')).join('.');
    }

    kbkFromProject(budget_level: string, funding_source: string): string {
        let regionKey = this.regionFilter;
        if (!this.regionFilter.endsWith("0101") && ["01", "02"].includes(budget_level)) {
            const regionPrefix = this.regionFilter.slice(0, -4); 
            const replacementRegion = this.regionList.find(region => 
                region.code.startsWith(regionPrefix) && region.code.endsWith("0101")
            );
            if (replacementRegion) {
                regionKey = replacementRegion.code;
            }
        }
        const kbkObject = this.findKbkObject(budget_level, funding_source, regionKey);
        if (!kbkObject) return "...";  // Проверяем null перед передачей в kbkToString
        return this.kbkToString(kbkObject);
    }
}
