// Интерфейс для группы трансфертов
export interface TransfertGroup {
    code: string; // Код группы трансфертов
    name_ru: string; // Название группы на русском языке
    project_type: string[]; // Список типов проектов, относящихся к данной группе
    exclude_funding: string[]; // Источники финансирования, исключенные из данной группы
    include_funding: string[]; // Источники финансирования, включенные в данную группу
}

// Интерфейс для словаря (справочника) с кодами и названиями
export interface IDict {
    id: number; // Уникальный идентификатор
    name_ru: string; // Название на русском языке
    name_kk?: string; // Название на казахском языке (опционально)
    name_en?: string; // Название на английском языке (опционально)
    code: string; // Код справочника
}

// Интерфейс для данных о трансфертах, расширяющий ProjectCheckerData
export interface TransferData extends ProjectCheckerData {
    direction: string; // Направление трансферта
    kbk_data: Map<string, KbkData[]>; // Данные по кодам бюджетной классификации (КБК)
    year: number; // Год трансферта
}

// Базовый интерфейс для проверки проектов
export interface ProjectCheckerData {
    checked: boolean; // Флаг, отмечен ли проект
    transfer_group: string; // Группа трансфертов, к которой относится проект
    transfer_group_list: string[]; // Список доступных групп трансфертов
    project_from_bank: ProjectFromBank; // Данные о проекте из банка проектов
}

// Интерфейс для информации о регионе, связанном с трансфертом
export interface TransferRegion {
    checked: boolean; // Флаг, отмечен ли регион
    region: IDict; // Информация о регионе в виде справочника
    disabled: boolean; // Блок выбора в случае, если имеются данные по данному региону
}

// Интерфейс для структуры источников финансирования
export interface FundingSource {
    code_funding_level: string; // Код уровня финансирования
    code_1_level: string; // Код первого уровня
    code_2_level: string; // Код второго уровня
    code_3_level: string; // Код третьего уровня
    code_4_level: string; // Код четвертого уровня
    code_5_level: string; // Код пятого уровня
}

// Интерфейс для данных о проекте из банка проектов
export interface ProjectFromBank {
    id: number; // Уникальный идентификатор проекта
    code: string; // Код проекта
    budget_region: string | null; // Регион бюджета (может отсутствовать)
    name_kz: string; // Название проекта на казахском языке
    name_ru: string; // Название проекта на русском языке
    abp: number; // Код АБП (Администратор Бюджетных Программ)
    is_archive: boolean; // Признак архивного проекта
    funding: string; // Источник финансирования
    amount_rep_budget: number; // Сумма из республиканского бюджета
    amount_local_budget: number; // Сумма из местного бюджета
    project_type: string; // Тип проекта
    branch: string | null; // Отрасль (может отсутствовать)
    prg: string | null; // Программа (может отсутствовать)
    concept: string | null; // Концепция проекта (может отсутствовать)
    begin_date: string; // Дата начала проекта (лучше использовать Date)
    end_date: string; // Дата завершения проекта (лучше использовать Date)
    location: string | null; // Местоположение проекта (может отсутствовать)

    // Цели проекта
    purpose_kz: string; // Цель на казахском языке
    purpose_ru: string; // Цель на русском языке

    // Задачи проекта
    task_name_kz: string; // Задача на казахском языке
    task_name_ru: string; // Задача на русском языке

    // Результаты проекта
    result_kz: string; // Итоговый результат на казахском языке
    result_ru: string; // Итоговый результат на русском языке
    indicator_kz: string; // Индикатор на казахском языке
    indicator_ru: string; // Индикатор на русском языке
    direct_result_kz: string; // Прямой результат на казахском языке
    direct_result_ru: string; // Прямой результат на русском языке
    final_result_kz: string; // Финальный результат на казахском языке
    final_result_ru: string; // Финальный результат на русском языке
    key_indicator_kz: string; // Ключевой показатель на казахском языке
    key_indicator_ru: string; // Ключевой показатель на русском языке

    unit: string | null; // Единица измерения (может отсутствовать)
    user_id: string; // Идентификатор пользователя, создавшего проект
    created_at: string; // Дата создания проекта (лучше использовать Date)
    updated_at: string; // Дата последнего обновления проекта (лучше использовать Date)
    is_deleted: boolean; // Флаг, удален ли проект
}

// Функция возвращает список групп трансфертов
export function dict_transfert_group(): TransfertGroup[] {
    return [
        {
            code: "01",
            name_ru: "Целевые текущие трансферты",
            project_type: ["current", "other"], // Для текущих и прочих проектов
            exclude_funding: ["3"], // Исключает финансирование с кодом "3"
            include_funding: [],
        },
        {
            code: "02",
            name_ru: "Трансферты по развитию",
            project_type: ["development", "imported"], // Для проектов развития и импортных
            exclude_funding: ["3"],
            include_funding: [],
        },
        {
            code: "03",
            name_ru: "Трансферты общего характера",
            project_type: ["current", "other"], // Для текущих и прочих проектов
            exclude_funding: ["3"],
            include_funding: [],
        },
        {
            code: "04",
            name_ru: "Кредиты",
            project_type: [],
            include_funding: ["3"], // Только для финансирования с кодом "3"
            exclude_funding: [],
        },
        {
            code: "05",
            name_ru: "Бюджетные изъятия",
            project_type: ["current", "other"], // Для текущих и прочих проектов
            exclude_funding: ["3"],
            include_funding: [],
        },
        {
            code: "06",
            name_ru: "Прочие",
            project_type: [],
            exclude_funding: ["3"],
            include_funding: [],
        },
    ];
}

// Фильтрует список `ProjectCheckerData`, оставляя только элементы, относящиеся к указанной группе трансфертов
export function applyFilters(
    list: ProjectCheckerData[], // Список проектов для фильтрации
    groupFilterCode: string // Код группы трансфертов для фильтрации
): ProjectCheckerData[] {
    // Находим группу трансфертов по переданному коду
    const filterGroup: TransfertGroup | undefined = dict_transfert_group().find(
        (group) => group.code === groupFilterCode
    );

    // Если группа не найдена, возвращаем исходный список без фильтрации
    if (!filterGroup) return list;

    // Возвращаем только те элементы списка, у которых `transfer_group_list` содержит нужный код
    return list.filter((value) =>
        value.transfer_group_list.includes(groupFilterCode)
    );
}

// Устанавливает список групп трансфертов в объект `ProjectCheckerData`
export function setTransferGroup(td: ProjectCheckerData) {
    // Инициализируем массив, если он отсутствует
    if (!td.transfer_group_list) {
        td.transfer_group_list = [];
    }

    // Если источник финансирования НЕ равен "3" и проект типа "current" или "other"
    if (
        td.project_from_bank?.funding !== "3" &&
        (td.project_from_bank?.project_type === "current" || td.project_from_bank?.project_type === "other")
    ) {
        td.transfer_group_list.push("01", "03", "05"); // Добавляем три группы
    }

    // Если источник финансирования НЕ равен "3" и проект типа "development" или "imported"
    if (
        td.project_from_bank?.funding !== "3" &&
        (td.project_from_bank?.project_type === "development" || td.project_from_bank?.project_type === "imported")
    ) {
        td.transfer_group_list.push("02");
    }

    // Если источник финансирования РАВЕН "3"
    if (td.project_from_bank?.funding === "3") {
        td.transfer_group_list.push("04");
    }

    // Если источник финансирования НЕ равен "3"
    if (td.project_from_bank?.funding !== "3") {
        td.transfer_group_list.push("06");
    }
}

// Интерфейс для сохранения данных о трансферте
export interface TransferSaveData {
    transfer_group: string | undefined; // Группа трансфертов (может быть неопределенной/null)
    expense_code: string | undefined; // Код расхода (может быть неопределенным/null)
    direction: string | undefined; // Направление трансферта (числовой идентификатор)
    funding_source: string | undefined; // Источник финансирования (числовой идентификатор)
    budget_level: string | undefined; // Уровень бюджета (числовой идентификатор)
    abp?: number; // Администратор Бюджетных Программ (опционально)
    prg?: number | null; // Программа (опционально)
    ppr?: number | null; // Подпрограмма (опционально)
    region: string; // Регион (может быть null, но не undefined)
    year: number; // Год трансферта
    user_id: string; // Идентификатор пользователя, связанного с трансфертом
    deleted?: boolean;
}

// Интерфейс для кодов КБК (Классификация Бюджетных Кодов)
export interface KbkCode {
    abp: number; // Анализ Бюджетных Программ (АБП)
    prg: number | null; // Программа
    ppr?: number | null; // Подпрограмма (опционально)
}

// Интерфейс для источников финансирования
export interface TransferSources {
    funding_source: string; // Источник финансирования (код)
    budget_level: string; // Уровень бюджета (код)
}

// Интерфейс для данных о КБК
export interface KbkData {
    transfer_sources: TransferSources; // Источники финансирования, связанные с КБК
    kbk_code: KbkCode; // Коды КБК (АБП, программа, подпрограмма)
}

