/* eslint-disable space-before-blocks */
<template>
    <div id="dict-book">
        <div id="navbar">
            <b-navbar style="background-color: #052640" fixed="top">
                <b-navbar-nav class="book-form-navbar">
                    <multiselect
                        v-model="value"
                        @input="selectDict(value)"
                        :options="directory"
                        :preselect-first="true"
                        label="name"
                        placeholder="Pick a value"
                        :max-height="500">
                    </multiselect>
                </b-navbar-nav>

                <b-button :disabled="!UserRoles.includes('dict_editor')" @click="getPassport" class="button-box" variant="info">Паспорт</b-button>

                <b-navbar-nav class="ml-auto">
                    <b-nav-form>
                        <b-form-input
                            @keydown.enter.prevent
                            size="lg"
                            class="search-nav"
                            placeholder="search"
                            @input="debouncedLoadDirectory"
                            v-model="filter">
                        </b-form-input>
                    </b-nav-form>
                </b-navbar-nav>
            </b-navbar>
        </div>
        <!-- Модалки для добавления/редактирования -->
        <template>
            <!-- Для редактирования -->
            <b-modal
                v-if="!selectedDictIsGuMain"
                @hidden="resetModal"
                id="modal-lg"
                size="md"
                v-model="ShowModal"
                title="Edit">

                <div class="modal-body">
                    <div v-for="col in activeDict.cols" :key="col.key">
                        <b-row>
                            <b-col md="3">
                                <label
                                    v-if="col.key !== 'create_date' && col.key !== 'update_date' && col.key !== 'updateDate' && col.key !== 'action' && col.key !== 'userName'"
                                    for="input-valid">
                                    <template v-if="col.label">
                                        {{ col.label }}
                                    </template>
                                    <template v-else>
                                        {{ col.key }}
                                    </template>
                                </label>
                            </b-col>

                            <b-col md="9">
                                <form class="sign-up" @submit.prevent="checkForm">
                                    <b-form-group
                                        class="book-dict-select-form"
                                        v-if="col.key === 'parent'">
                                        <multiselect
                                            v-model="NewItem[col.key]"
                                            :options="activeDictItems.filter((item) =>
                                         NewItem.id !== item.parent && NewItem.id !== item.id).map((type) => type.id)"
                                            :custom-label="(opt) =>
                                             activeDictItems.find((x) => x.id === opt).name_ru">
                                        </multiselect>
                                    </b-form-group>

                                    <b-form-group class="book-dict-select-form" v-if="col.type === 'select-stat'">
                                        <multiselect
                                            v-model="NewItem[col.key]"
                                            :options="statIndustry"
                                            label="shortname">
                                        </multiselect>
                                    </b-form-group>

                                    <b-form-group class="book-dict-select-form" v-if="col.type === 'select-KatoStat'">
                                        <multiselect
                                            v-model="NewItem[col.key]"
                                            :options="KatoStat"
                                            label="nameRu">
                                        </multiselect>
                                    </b-form-group>

                                    <b-form-group class="book-dict-select-form" v-if="col.type === 'select-modules'">
                                        <multiselect
                                            v-model="NewItem[col.key]"
                                            :options="sicModules"
                                            label="nameRu">
                                        </multiselect>
                                    </b-form-group>

                                    <b-form-group class="book-dict-select-form" v-if="col.type === 'select-multiple'">
                                        <multiselect
                                            v-model="NewItem[col.key]"
                                            :options="col.options"
                                            :multiple="true"
                                            :close-on-select="false"
                                            :clear-on-select="false"
                                            :preserve-search="true"
                                        >
                                            <template
                                                slot="selection"
                                                slot-scope="{ isOpen }"
                                            >
                                                <span
                                                    class="multiselect__single"
                                                    v-if="!isOpen"
                                                >
                                                    <template v-for="item of NewItem[col.key]">{{`${item}, `}}</template>
                                                </span>
                                            </template>
                                        </multiselect>
                                    </b-form-group>

                                    <b-form-group
                                    v-if="col.type === 'text'
                                      &&
                                      col.key !== 'parent'
                                      &&
                                      col.key !== 'userName'
                                      &&
                                      col.key !== 'description'">
                                        <b-form-input
                                            v-model.trim="NewItem[col.key]"
                                            type="text"
                                            :formatter="handleFormattedInput[col.key]"
                                            :class="$v.NewItem[col.key].$error ? 'is-invalid' : ''"
                                            :disabled="col.readonly"
                                            class="input"/>
                                        <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].required && $v.NewItem[col.key].required !== undefined"
                                           class="invalid-feedback">
                                           Обязательное поле
                                        </p>
                                        <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].numeric && $v.NewItem[col.key].numeric !== undefined"
                                           class="invalid-feedback"> Только цифры
                                        </p>
                                        <p v-if="$v.NewItem[col.key].$dirty && (!$v.NewItem[col.key].minLength || !$v.NewItem[col.key].maxLength) && $v.NewItem[col.key].minLength !== undefined && $v.NewItem[col.key].maxLength !== undefined"
                                           class="invalid-feedback"> Длина {{ col.validation.minLength }} символов
                                        </p>
                                    </b-form-group>

                                    <b-form-group v-if="col.key === 'description'">
                                        <b-form-textarea
                                            v-model="NewItem[col.key]"
                                            type="text"
                                            class="input"
                                            id="textarea-small"
                                            size="sm"
                                        ></b-form-textarea>
                                    </b-form-group>

                                    <b-form-group v-if="col.type === 'asyncSelect'">
                                        <multiselect
                                            v-model="NewItem[col.key]"
                                            :options="asyncSelectOptions[col.key]"
                                            label="label"
                                            :track-by="col.trackBy"
                                            :class="$v.NewItem[col.key].$error ? 'is-invalid' : ''"
                                            @search-change="(query) => handleLoadSelects(col.key, query, col.selectsUrl, col.loadOnce)">
                                        </multiselect>
                                        <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].required && $v.NewItem[col.key].required !== undefined"
                                           class="invalid-feedback">
                                           Обязательное поле
                                        </p>
                                        <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].numeric && $v.NewItem[col.key].numeric !== undefined"
                                           class="invalid-feedback"> Только цифры
                                        </p>
                                        <p v-if="$v.NewItem[col.key].$dirty && (!$v.NewItem[col.key].minLength || !$v.NewItem[col.key].maxLength) && $v.NewItem[col.key].minLength !== undefined && $v.NewItem[col.key].maxLength !== undefined"
                                           class="invalid-feedback"> Длина {{ col.validation.minLength }} символов
                                        </p>
                                    </b-form-group>
                                    


                                    <b-form-group v-if="col.type === 'switch'">
                                        <b-form-checkbox v-model="NewItem[col.key]" switch :disabled="col.readonly"/>
                                    </b-form-group>

                                    <b-form-group class="book-dict-select-form" v-if="col.type === 'select-async-multiple'">
                                        <multiselect
                                            v-model="NewItem[col.key]"
                                            :options="asyncSelectOptions[col.key]"
                                            :multiple="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            :preserve-search="false"
                                            label="label"
                                            track-by="id"
                                            :class="$v.NewItem[col.key].$error ? 'is-invalid' : ''"
                                            @search-change="(query) => handleLoadSelects(col.key, query, col.selectsUrl)"
                                        >
                                            <template
                                                slot="selection"
                                                slot-scope="{ isOpen }"
                                            >
                                                <span
                                                    class="multiselect__single"
                                                    v-if="!isOpen"
                                                >
                                                    <template v-for="item of NewItem[col.key]">{{`${item.shortLabel}, `}}</template>
                                                </span>
                                            </template>
                                        </multiselect>
                                        <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].required && $v.NewItem[col.key].required !== undefined"
                                           class="invalid-feedback">
                                           Обязательное поле
                                        </p>
                                        <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].numeric && $v.NewItem[col.key].numeric !== undefined"
                                           class="invalid-feedback"> Только цифры
                                        </p>
                                        <p v-if="$v.NewItem[col.key].$dirty && (!$v.NewItem[col.key].minLength || !$v.NewItem[col.key].maxLength) && $v.NewItem[col.key].minLength !== undefined && $v.NewItem[col.key].maxLength !== undefined"
                                           class="invalid-feedback"> Длина {{ col.validation.minLength }} символов
                                        </p>
                                    </b-form-group>

                                    <b-form-group
                                        v-if="col.type === 'date'
                                    &&
                                    col.key !== 'create_date'
                                    &&
                                    col.key !== 'update_date'
                                    &&
                                    col.key !== 'updateDate'">
                                        <b-form-input
                                            :value="millisToDate(NewItem[col.key])"
                                            type="date"
                                            @input="value => handleInputDate(col.key, value)"
                                            :class="$v.NewItem[col.key].$error ? 'is-invalid' : ''"
                                            class="input"/>
                                            <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].required && $v.NewItem[col.key].required !== undefined"
                                            class="invalid-feedback"> Обязательное поле
                                            </p>
                                    </b-form-group>

                                    <b-form-group v-if="col.type === 'number'
                                    && col.key !== 'parent'
                                    && col.key !== 'minlevel'">
                                        <b-form-input
                                            v-model.trim="NewItem[col.key]"
                                            type="number"
                                            class="input"
                                            :class="$v.NewItem[col.key].$error ? 'is-invalid' : ''"
                                        />
                                        <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].required && $v.NewItem[col.key].required !== undefined"
                                           class="invalid-feedback">
                                           Обязательное поле
                                        </p>
                                        <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].numeric && $v.NewItem[col.key].numeric !== undefined"
                                           class="invalid-feedback"> Только цифры
                                        </p>
                                        <p v-if="$v.NewItem[col.key].$dirty && (!$v.NewItem[col.key].minLength || !$v.NewItem[col.key].maxLength) && $v.NewItem[col.key].minLength !== undefined && $v.NewItem[col.key].maxLength !== undefined"
                                           class="invalid-feedback"> Длина {{ col.validation.minLength }} символов
                                        </p>
                                    </b-form-group>

                                    <b-form-group v-if="col.key === 'minlevel'">
                                        <b-form-input
                                            v-model="NewItem[col.key]"
                                            :formatter="handleFormattedInput[col.key]"
                                            type="number"
                                            class="input"/>
                                    </b-form-group>

                                    <b-form-group v-if="col.type === 'select' && col.options">
                                        <b-form-select
                                            v-model="NewItem[col.key]"
                                            :options="col.options"
                                        ></b-form-select>
                                    </b-form-group>

                                    <b-form-group v-else-if="col.type === 'select'">
                                        <b-form-select
                                            class="input"
                                            v-model="NewItem[col.key]"
                                            :options="activeDict.selects"
                                        ></b-form-select>
                                    </b-form-group>
                                </form>
                            </b-col>
                        </b-row>
                    </div>
                </div>

                <template #modal-footer>
                    <div class="w-100">
                        <p class="float-left">
                            Редактируемый справочник - {{ activeDict.name }}
                        </p>
                        <b-button
                            type="submit"
                            variant="success"
                            size="tr"
                            class="float-right"
                            @click="checkForm">
                            Сохранить
                        </b-button>

                        <b-button
                            v-if="activeDict.tableName === 'appointment' && NewItem.id"
                            type="submit"
                            variant="danger"
                            size="tr"
                            class="float-right"
                            @click="confirmDeletion">
                            Удалить
                        </b-button>
                    </div>
                </template>
            </b-modal>

            <!-- Для добавления -->
            <b-modal
                v-else
                @hidden="resetModal"
                id="modal-lg"
                size="md"
                v-model="ShowModal"
                title="Редактирование головных гос. учреждений">
                <div class="modal-body">
                    <div>
                        <b-row>
                            <b-col md="3">
                                <label for="input-valid">
                                     Регион
                                </label>
                            </b-col>
                            <b-col md="9">
                                <form class="sign-up">
                                    <b-form-group class="book-dict-select-form">
                                        <multiselect
                                            v-model="selectedModalRegion"
                                            deselect-label="Can't remove this value"
                                            track-by="code"
                                            :custom-label="(opt) => `${opt.code} - ${opt.name_ru}`"
                                            placeholder="Выберите регион"
                                            :options="regions"
                                            @input="setSelectedModalRegionResettingAbp"
                                            :allow-empty="false"
                                        >
                                            <template
                                                slot="singleLabel"
                                                slot-scope="{ option }"
                                            >
                                                <strong>{{ option.code + ' - ' + option.name_ru }}</strong>
                                                </template>
                                        </multiselect>
                                    </b-form-group>
                                </form>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="3">
                                <label for="input-valid">
                                     АБП
                                </label>
                            </b-col>
                            <b-col md="9">
                                <form class="sign-up">
                                    <b-form-group class="book-dict-select-form">
                                        <multiselect
                                            v-model="selectedModalAbp"
                                            track-by="abp"
                                            placeholder="Выберите АБП"
                                            :custom-label="(opt) => `${opt.abp} - ${opt.name_ru}`"
                                            :options="abpModalItems"
                                            :allow-empty="true">
                                            <template
                                                slot="singleLabel"
                                                slot-scope="{ option }">
                                                <strong>{{ option.abp }} - {{ option.name_ru }}</strong>
                                            </template>
                                        </multiselect>
                                    </b-form-group>
                                </form>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="3">
                                <label for="input-valid">
                                    ГУ
                                </label>
                            </b-col>
                            <b-col md="9">
                                <form class="sign-up">
                                    <b-form-group>
                                        <b-form-radio-group
                                            v-model="selectedGuMain"
                                            :options="guModalItems"
                                            text-field="label"
                                            value-field="code"
                                            name="gu-main"
                                        ></b-form-radio-group>
                                    </b-form-group>
                                </form>
                            </b-col>
                        </b-row>
                    </div>
                </div>

                <template #modal-footer>
                    <div class="w-100">
                        <p class="float-left">
                            Редактируемый справочник - {{ activeDict.name }}
                        </p>
                        <b-button
                            type="submit"
                            variant="success"
                            size="tr"
                            class="float-right"
                            @click="checkForm">
                            Сохранить
                        </b-button>
                    </div>
                </template>
            </b-modal>
        </template>
        <enstru-form v-if="value.tableName == 'enstru'" year="2011"></enstru-form>
        <b-card class="table-container book-dict-table">
            <template #header>
                <b-form-row>
                    <div>
                        <multiselect
                            v-if="selectedDictIsGuMain"
                            v-model="selectedRegion"
                            deselect-label="Can't remove this value"
                            track-by="code"
                            :custom-label="(opt) => `${opt.code} - ${opt.name_ru}`"
                            placeholder="Выберите регион"
                            :options="regions"
                            :allow-empty="false"
                            @input="setSelectedRegionResettingAbp"
                        >
                            <template
                                slot="singleLabel"
                                slot-scope="{ option }"
                            >
                                <strong>{{ option.code + ' - ' + option.name_ru }}</strong>
                            </template>
                        </multiselect>
                        <multiselect
                            v-if="selectedDictIsGuMain"
                            v-model="selectedAbp"
                            track-by="abp"
                            placeholder="Выберите АБП"
                            :custom-label="(opt) => `${opt.abp} - ${opt.name_ru}`"
                            :options="abpItems"
                            :allow-empty="true"
                            @input="setSelectedAbp">
                            <template
                                slot="singleLabel"
                                slot-scope="{ option }">
                            <strong>{{ option.abp }} - {{ option.name_ru }}</strong>
                        </template>
                        </multiselect>
                        <multiselect
                            v-if="selectedDictIsGuMain"
                            v-model="selectedFilterOption"
                            :options="filterOptions"
                            :allow-empty="false">
                        </multiselect>

                        <b-button
                            v-if="!selectedDictIsGuMain && isCertainUser"
                            @click="addItem"
                            :disabled="isNonAddable"
                            variant="success">
                            Добавить
                        </b-button>

                        <b-button
                            v-if="isCertainUser"
                            @click="editItem"
                            variant="primary">
                            Изменить
                        </b-button>
                        <b-dropdown text="Колонки" variant="secondary">
                            <b-form-checkbox
                                v-for="option in tableFields"
                                :key="option.key"
                                v-model="select"
                                @change="toggleAll(option)">
                                {{ option.key }}
                            </b-form-checkbox>
                        </b-dropdown>
                        <b-dropdown v-if="activeDict.filters" class="filter-dropdown" variant="default">
                        <template #button-content>
                            <span class="lc small-padding"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i class="icon icon-keyboard"></i></span>
                        </template>
                        <b-form-group v-for="filter in activeDict.filters" :key="filter.key" label="Плановый период" class="small-padding">
                            <multiselect
                            :value="filterParams[filter.key]"
                            :options="asyncSelectOptions[filter.key]"
                            @input="value => handleFilterParamsInput(filter.key, value)"
                            label="label"
                            @search-change="(query) => handleLoadSelects(filter.key, query, filter.url)">
                            <template
                            slot="singleLabel"
                            slot-scope="{ option }">
                            <strong>{{ option.label }}</strong>
                            </template>
                            </multiselect>
                        </b-form-group>
                    </b-dropdown>
                    </div>


                    <div style="display: flex; align-items: center; justify-content: space-between">
                        <div style="background-color: #EEF1F8; cursor: pointer; margin-right: 10px" @click="downloadRep">
                            <div>
                        <span style="border-right: 1px solid #E2E5EB;padding: 0 10px;">
                            <i class="icon icon-download" style="color: #01AC50;margin-right: 5px;"></i>
                            Скачать
                        </span>
                            </div>
                        </div>

                        <b-form-checkbox v-if="activeDict.hasParent" switch size="lg"
                                         v-b-tooltip.hover title="Иерархический вид"
                                         v-model="isHidden"
                                         switches
                                         stacked
                                         name="checkbox-1"
                                         value="is-hidden"
                                         unchecked-value="showAll"
                                         @change="turnOnModeTree()">
                        </b-form-checkbox>

                        <div>
                            <span class="button-span">Всего: {{ itemCount }}</span>
                        </div>

                    </div>
                </b-form-row>
            </template>

            <template>
                <div class="table-container">
                    <b-overlay
                        :show="loading"
                        rounded="sm"
                        :opacity="0.75"
                        spinner-variant="primary"
                        spinner-type="grow"
                        spinner-small
                    >
                        <b-table
                        id="my-table"
                            responsive="true"
                            bordered
                            head-variant="light"
                            sticky-header
                            no-border-collapse
                            striped
                            :sort-by.sync="sortBy"
                            :no-local-sorting="noLocalSorting"
                            thead-class="bold_header"
                            selected-variant="danger"
                            selectable
                            select-mode="single"
                            @row-selected="onRowSelected"
                            :fields="tableFields"
                            :items="activeDictItems"
                            small
                            :tbody-tr-class="rowClass"
                        >
                            <template #cell(selected)="{ rowSelected }">
                                <template v-if="rowSelected">
                                    <span aria-hidden="true">&check;</span>
                                </template>

                                <template v-else>
                                    <span aria-hidden="true">&nbsp;</span>
                                </template>
                            </template>

                            <template #cell(action)="data">
                                <b-button
                                    v-if="activeDictItems.find((x) => x.parent === data.item.id) && isHidden === 'is-hidden'"
                                    @click="openChild(data.item)"
                                    class="m-0">
                                    <i class="icon icon-keyboard icon-rotate-90"> </i>
                                </b-button>
                            </template>

                            <template #cell(parent)="data">
                                {{ getDictItemById(data.item.parent) }}
                            </template>

                            <template #cell(parentId)="data">
                                {{ getDictItemById(data.item.parentId) }}
                            </template>

                            <template class="tr" #cell(create_date)="data">
                                <div class="tr">
                                    {{ millisToDate(data.item.createDate) }}
                                </div>
                            </template>

                            <template class="tr" #cell(createDate)="data">
                                <div class="tr">
                                    {{ millisToDate(data.item.createDate) }}
                                </div>
                            </template>

                            <template class="tr" #cell(begDate)="data">
                                <div class="tr">
                                    {{ millisToDate(data.item.begDate) }}
                                </div>
                            </template>

                            <template class="tr" #cell(endDate)="data">
                                <div class="tr">
                                    {{ millisToDate(data.item.endDate) }}
                                </div>
                            </template>

                            <template #cell(update_date)="data">
                                <div class="tr ">
                                    {{ millisToDate(data.item.updateDate) }}
                                </div>
                            </template>

                            <template #cell(beg_date)="data">
                                <div class="tr">
                                    {{ millisToDate(data.item.beg_date) }}
                                </div>
                            </template>

                            <template #cell(begin_date)="data">
                                <div class="tr">
                                    {{ millisToDate(data.item.begin_date) }}
                                </div>
                            </template>

                            <template #cell(end_date)="data">
                                <div class="tr">
                                    {{ millisToDate(data.item.end_date) }}
                                </div>
                            </template>

                            <template #cell(startdate)="data">
                                <div class="tr">
                                    {{ millisToDate(data.item.startDate) }}
                                </div>
                            </template>

                            <template #cell(updateDate)="data">
                                <div class="tr">
                                    {{ millisToDate(data.item.updateDate) }}
                                </div>
                            </template>

                            <template #cell(statIndustry)="data">
                                <div class="tr">
                                    {{ data.item.statIndustry ? data.item.statIndustry.shortname : ""}}
                                </div>
                            </template>

                            <template v-if="activeDict === 'kato'" #cell(statKato)="data">
                                <div class="tr">
                                    {{ data.item.statKato ? data.item.statKato.name_ru : "" }}
                                </div>
                            </template>

                            <template #cell(startDate)="data">
                                <div class="tr">
                                    {{ millisToDate(data.item.startDate) }}
                                </div>
                            </template>

                            <template v-if="activeDict.tableName !== 'appointment'" #cell(module)="data">
                                <div class="tr">
                                    {{data.item.module ? data.item.module.nameRu : ""}}
                                </div>
                            </template>
                            <template v-else #cell(module)="data">
                                <div class="tr">
                                    {{ data.item.module.label }}
                                </div>
                            </template>

                            <template v-if="activeDict.tableName === 'appointment'" #cell(operation)="data">
                                <div v-if="data.item.operation" class="tr">
                                    {{ data.item.operation.label }}
                                </div>
                            </template>

                            <template #cell(orgType)="data">
                                <div class="tr">
                                    {{ data.item.orgType.code }} - {{ data.item.orgType.nameRu }}
                                </div>
                            </template>

                            <template #cell(budgetLevelId)="data">
                                <div class="tr">
                                    <div v-if="data.item.budgetLevelId">
                                        {{ data.item.budgetLevelId.id }} - {{ data.item.budgetLevelId.nameRu }}
                                    </div>
                                </div>
                            </template>
                        </b-table>
                    </b-overlay>
                </div>
            </template>

            <template #footer>
                <b-pagination
                    v-show="isHidden !== 'is-hidden' && !selectedDictIsGuMain"
                    aria-controls="my-table"
                    size="sm"
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    @input="handlePageChange"
                ></b-pagination>
            </template>
        </b-card>

    </div>
</template>


<script>
import store from '@/services/store';
import {validationMixin} from 'vuelidate';
import {required, helpers, minLength, maxLength, numeric} from 'vuelidate/lib/validators';
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';
import Multiselect from 'vue-multiselect';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import dicts from './dicts.json';
import {Ax} from '@/utils';
import { debounce, cloneDeep } from 'lodash'
import EnstruForm from './enstru-form.vue'

const alpha = helpers.regex('alpha', /^[A-Za-z0-9-,.()_{/} ]*$/);
const Cyrillic = helpers.regex('Cyrillic', /^[А-Яа-я0-9-,.()_{/}) ]*$/);
const dictUpdateIncludeUsername = ['gu_kgkp']
const nonAddableDicts = ['enstru']
export default {
    name: 'book-dict',
    components: {multiselect: Multiselect, EnstruForm},

    computed: {
        rows() {
            return this.itemCount//this.activeDictItems.length;
        },

        selectedDictIsGuMain() {
            return this.selectedDict === 'gu_main'
        },

        isNonAddable() {
            return nonAddableDicts.includes(this.selectedDict)
        },

        // Certain user only in prod/release. In test allows for everyone
        isCertainUser() {
            const isTestEnviroment = this.server === 'MASTER'
            if (isTestEnviroment) {
                return true
            }

            return this.userSub === '98862e7d-4719-4aee-bf6f-6d7ac9863b59'
        }
    },

    watch: {
        currentPage() {
            this.loadDirectory()
        },

        ShowModal(value) {
            if (value) {
                this.selectedModalRegion = this.selectedRegion
                if (this.selectedAbp !== null && this.selectedAbp.abp !== '' && this.selectedAbp.abp !== null) {
                    this.selectedModalAbp = this.selectedAbp
                    this.guModalItems = this.allDictItems
                }
            }
        },

        selectedModalRegion(value) {
            this.guModalItems = []
            if (value !== null)
                this.loadAbpItems(value.code)
        },

        selectedModalAbp(value) {
            if (value !== null && value.abp !== '' && value.abp !== null)
                this.loadGuMainDirectory(this.selectedModalRegion.code, value.abp)
            else
                this.guModalItems = []
        },

        selectedFilterOption(value) {
            this.filterGuMainItemsBy(value)
        },
    },

    mixins: [validationMixin],

    async mounted() {
        await this.setServerInfo()
        await this.loadDictItems()
        await this.loadRegions()
    },

    data() {
        return {
            UserRoles: store.state.user.roles,
            userSub: store.state.user.sub,
            server: null,
            value: '',
            activeDict: {},
            tableFields: [],
            activeDictItems: [],
            allDictItems: [],
            ShowModal: false,
            NewItem: {},
            filterParams: {},
            perPage: 50,
            currentPage: 1,
            directory: dicts,
            filter: '',
            passport: [],
            KatoStat: [],
            statIndustry: [],
            sicModules: [],
            EditPass: false,
            isHidden: 'showAll',
            textRight: '',
            sortBy: '',
            noLocalSorting: false,
            filterOn: null,
            selectedDict: '',
            loading: false,
            itemCount: 0,
            regions: [],
            abpItems: [],
            abpModalItems: [],
            selectedRegion: null,
            selectedAbp: null,
            selectedModalRegion: null,
            selectedModalAbp: null,
            guModalItems: [],
            selectedGuMain: null,
            filterOptions: ['Все записи', 'Головные', 'Без головного ГУ'],
            selectedFilterOption: 'Все записи',
            asyncSelectOptions: {},
            handleFormattedInput: {}
        };
    },
    validations() {
            const NewItem = {}
            const formatter = {}
            for (const key in this.activeDict.cols) {
                if (Object.hasOwnProperty.call(this.activeDict.cols, key)) {
                    const element = this.activeDict.cols[key];
                    NewItem[element.key] = {}
                    formatter[element.key] = value => value
                    if (element.validation) {
                        if (element.validation.required) {
                            NewItem[element.key].required = required
                        }
                        if (element.validation.minLength) {
                            NewItem[element.key].minLength = minLength(element.validation.minLength)
                        }
                        if (element.validation.maxLength) {
                            const maximumLength = element.validation.maxLength
                            NewItem[element.key].maxLength = maxLength(maximumLength)
                            const copiedPreviousFunc = formatter[element.key].bind({});
                            formatter[element.key] = (value) => {
                                value += ''
                                value = value.length > maximumLength ? value.substring(0, maximumLength) : value
                                return copiedPreviousFunc(value)
                            }
                        }
                        if (element.validation.numeric) {
                            NewItem[element.key].numeric = numeric
                            const copiedPreviousFunc = formatter[element.key].bind({});
                            formatter[element.key] = value => {
                                value += ''
                                value = value.replace(/\D/g, "");
                                return copiedPreviousFunc(value)
                            }
                        }
                    }
                }
            }
            this.handleFormattedInput = formatter
            return {
                NewItem
            }
    },
    methods: {
        async setServerInfo() {
            try {
                const response = await fetch('/api/dict/get-budget-obl?obl=' + store.state._instanceCode)
                if (response.ok) {
                    const json = await response.json()
                    this.server = json.server
                } else {
                    this.makeToast('danger', 'get-budget-obl', `${response.status} - ${response.statusText}`);
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса get-budget-obl', error.toString());
            }
        },

        resetModeTree() {
            this.noLocalSorting = false;
            this.isHidden = 'showAll';
            if (this.isHidden === 'is-hidden') {
                this.perPage = 'none';
            } else {
                this.perPage = 50;
            }
        },

        turnOnModeTree() {
            this.noLocalSorting = !this.noLocalSorting;
            this.sortBy = null;
            if (this.isHidden === 'is-hidden') {
                this.perPage = 'none';
            } else {
                this.perPage = 50;
            }
        },

        onFiltered(filteredItems) {
            this.totalRows =  [...filteredItems.length];
            this.currentPage = 1;
            this.items.count = [...filteredItems.length];
        },

        openChild(item) {
            this.NewItem = !this.NewItem;
            this.activeDictItems.forEach((data) => {
                if (item.id === data.parent) {
                    data.open = !data.open;
                }
                if (data.layer > 1 && data.open === false) {
                    this.activeDictItems.forEach((item) => {
                        if (data.id === item.parent) {
                            item.open = false;
                        }
                    });
                }
            });
        },

        rowClass(item, type) {
            this.textRight = ' text-right';
            this.textLeft = 'text-left';
            if (!item || type !== 'row') {
                return;
            }
            if (!item.open) {
                return this.isHidden;
            }
            if (item.layer % 2 === 0 && this.isHidden === 'is-hidden') {
                return this.textRight;
            }
            if (item.layer !== 0 && this.isHidden === 'is-hidden') {
                return this.textLeft + ' table-primary';
            }
        },

        toggleAll(option) {
            if (option.thClass === 'true' && option.tdClass === 'true') {
                option.thClass = 'd-none';
                option.tdClass = 'd-none';
                this.select = true;
            } else if (option.thClass === 'd-none' && option.tdClass === 'd-none') {
                option.thClass = 'true';
                option.tdClass = 'true';
                this.select = true;
            }
        },

        checkEdit() {
            this.EditPass = this.activeDict.url === '/api/stat-dict/';
        },

        resetModal() {
            this.NewItem = {};
        },

        getPassport() {
            this.NewItem = this.passport.find(
                (x) => x.code === this.activeDict.tableName
            );
            if (this.NewItem) {
                this.ShowModal = true;
            } else {
                this.makeToast('Паспорт отсутствует', 'Ошибка !');
            }
        },

        async onRowSelected(row) {
            this.NewItem = {}
            this.NewItem = this.convertChosenRow(row)
            await this.loadAsyncSelectChoice()
        },

        convertChosenRow(row) {
            const copy = JSON.parse(JSON.stringify(row[0]))
            if (this.selectedDict === 'gu') {
                if (copy.orgAttribute === '1') {
                    copy.orgAttribute = true
                } else {
                    copy.orgAttribute = false
                }
            }
            return copy
        },

        handleInputDate(key, value) {
            if (!value.startsWith('0')) // if allow year to start with 0, would reset input
                this.NewItem = {...this.NewItem, [key]: value};
        },

        checkForm() {
            if (this.selectedDictIsGuMain) {
                this.reassignGuMain()
                return
            }
            this.$v.NewItem.$touch();
            if (!this.$v.NewItem.$error) {
                this.saveOrUpdate();
            }
        },

        async confirmDeletion() {
            try {
                const confirmation = await this.$bvModal.msgBoxConfirm(
                    'Подтверждаете удаление?',
                    {
                        title: 'Подтверждение',
                        size: 'lg',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Удалить',
                        cancelTitle: 'Отмена',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true
                    })

                    if (confirmation) {
                        const id = this.NewItem.id
                        await this.softDelete(id)
                        this.makeToast('Удалено', 'Сообщение', 'success')
                        this.ShowModal = false;
                        this.cleanItem();
                        await this.loadDirectory();
                    }
            } catch (error) {
                this.makeToast('Не удалось совершить удаление', 'Ошибка', 'danger')
                console.error(error.toString())
            }
        },

        // софт-Удаление (пока только таблица appointment)
        async softDelete(id) {
            const response = await fetch(`/api/dict/appointment/soft-delete/${id}`, { method: 'DELETE' })
            if (!response.ok) {
                throw new Error('')
            }
        },

        makeToast(message, title, variant) {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant,
                solid: true
            });
        },

        getDictItemById: function (ParentId) {
            for (const value of this.activeDictItems) {
                if (ParentId === value.id) {
                    return value.name_ru ? value.name_ru : value.nameRu
                }
            }
            return null;
        },

        selectDict: function (item) {
            this.activeDict = item;
            this.selectedDict = item.tableName
            this.checkEdit();
            this.tableFields = [...JSON.parse(JSON.stringify(this.activeDict.cols))];
            this.cleanItem();
            this.filter = ''
            this.filterParams = {};
            this.asyncSelectOptions = {};
            for (const iterator of item.cols) {
                if (iterator.type === 'asyncSelect' || iterator.type === 'select-async-multiple') {
                    this.asyncSelectOptions[iterator.key] = []
                }
            }
            if (item.filters) {
                for (const iterator of item.filters) {
                    this.asyncSelectOptions[iterator.key] = []
                }
            }
            this.select = [];
            this.currentPage = 1
            if (item.tableName === 'gu_main') {
                this.loadGuMainDirectory(this.regions[0].code, null)
                this.setSelectedRegionResettingAbp(this.regions[0])
            }
            else {
                this.loadDirectory()
            }
            this.resetModeTree();
        },

        handlePageChange(page) {
            this.currentPage = page
        },


        handleObjectInput(key, value, objectKey) {
            this.NewItem[key] = value[objectKey]
        },

        async loadSelects(key, query, selectsUrl) {
            const response = await fetch(selectsUrl + query)
            if (response.ok) {
                const res = await response.json()
                const items = res.items ? res.items : res

                // Labelling
                const column = this.activeDict.cols.find(item => item.key === key)
                if (column.labelBy) {
                    items.map(item => this.assignLabel(item, column.labelBy))
                } else {
                    items.map(item => {
                        const nameRu = item.nameRu ? item.nameRu : item.name_ru
                        const code = item.code ? item.code : item.fullCode
                        item.label = `${code} - ${nameRu}`
                        item.shortLabel = code
                        return item
                    })
                }

                if (this.asyncSelectOptions[key] === undefined)
                    this.asyncSelectOptions[key] = []
                this.asyncSelectOptions[key] = items
                this.asyncSelectOptions = JSON.parse(JSON.stringify(this.asyncSelectOptions))
            }
        },

        handleLoadSelects: debounce(async function(key, query, selectsUrl, loadOnce = false) {
            if (!loadOnce) {
                await this.loadSelects(key, query, selectsUrl)
            }
        }, 300),

        async loadAsyncSelectChoice() {
            for (const col of this.activeDict.cols) {
                if (col.loadOnce) {
                    let value = this.NewItem[col.key]
                    if (this.selectedDict === 'appointment') {
                        value = this.NewItem[col.key].module
                    }
                    const option = cloneDeep(
                        // TODO: only support objs, not primitive types. Make compatible for both
                        this.asyncSelectOptions[col.key].find(it => it[col.key][col.trackBy] === value)
                    )
                    this.NewItem[col.key] = option
                }
                else if (col.type === 'select-async-multiple') {
                    const chosenItems = []
                    for (const item of this.NewItem[col.key]) {
                        const response = await fetch(col.selectsUrl + item)
                        if (response.ok) {
                            const data = await response.json()
                            const loadedItem = data.items ? data.items[0] : data
                            const nameRu = loadedItem.nameRu ? loadedItem.nameRu : loadedItem.name_ru
                            const code = loadedItem.code ? loadedItem.code : loadedItem.fullCode
                            loadedItem.label = `${code} - ${nameRu}`
                            loadedItem.shortLabel = code
                            chosenItems.push(loadedItem)
                        }
                    }
                    this.NewItem[col.key] = chosenItems
                }
                else if (this.activeDict.tableName === 'bip_object_directions') {
                    const response = await fetch(col.selectsUrl)
                    if (response.ok) {
                        const items = await response.json()
                        const item = items.find(it => it.code === this.NewItem[col.key])

                        const nameRu = item.nameRu ? item.nameRu : item.name_ru
                        const code = item.code ? item.code : item.fullCode
                        item.label = `${code} - ${nameRu}`
                        
                        this.NewItem[col.key] = item
                    }
                }
                else if (col.trackBy) {
                    let query = this.NewItem[col.key]
                    let url = col.selectsUrl
                    // for objects
                    if (col.key === 'budgetLevelId') {
                        if (this.NewItem[col.key]) {
                            url += '/pages?search-text='
                            query = this.NewItem[col.key]?.code
                        } else {
                            return
                        }
                    }
                    const response = await fetch(url + query)
                    if (response.ok) {
                        const data = (await response.json())
                        const item = data.items ? data.items[0] : data[0] ? data[0] : data

                        if (col.labelBy) {
                            this.assignLabel(item, col.labelBy)
                        } else {
                            if (col.key === 'budgetLevelId') {
                                item.label = `${item.id} - ${item.name_ru}`
                            } else {
                                const nameRu = item.nameRu ? item.nameRu : item.name_ru
                                const code = item.code ? item.code : item.fullCode
                                item.label = `${code} - ${nameRu}`
                            }
                        }

                        this.NewItem[col.key] = item
                    }
                }
            }
        },

        handleFilterParamsInput(key, value) {
            this.filterParams[key] = value
            if (value.label === '-') {
                delete this.filterParams[key]
            }
            this.loadDirectory()
        },

        filterGuMainItemsBy(option) {
            switch (option) {
                case this.filterOptions[0]:
                    this.activeDictItems = this.allDictItems
                    return;
                case this.filterOptions[1]:
                    this.activeDictItems = this.allDictItems.filter(item => item.main === 'O')
                    return;
                    case this.filterOptions[2]:
                        let filteredItems = []
                        let temp = []
                        let includeFlag = true
                        let abp = this.allDictItems[0].abp_owner
                        this.allDictItems.forEach(item => {
                            if (item.abp_owner === abp) {
                                if (includeFlag)
                                    temp.push(item)
                                if (item.main === 'O') {
                                    includeFlag = false
                                    temp = []
                                }
                            } else {
                                abp = item.abp_owner
                                filteredItems = filteredItems.concat(temp)
                                includeFlag = true
                                temp = []
                                if (item.main === 'O') {
                                    includeFlag = false
                                } else {
                                    temp.push(item)
                                }
                            }
                        });
                        filteredItems = filteredItems.concat(temp)
                        this.activeDictItems = filteredItems
                    return;
                default:
                    return;
            }
        },

        async loadDictItems() {
            this.loading = true;
            try {
                const responses = await Promise.all([
                    fetch('/api/stat-dict/industry'),
                    fetch('/api/guide/modules/data')
                ]);

                const [statIndustryResponse, sicModulesResponse] = responses;

                if (statIndustryResponse.ok) {
                    this.statIndustry = await statIndustryResponse.json();
                }

                if (sicModulesResponse.ok) {
                    this.sicModules = await sicModulesResponse.json();
                }

            } catch (error) {
                console.error('Error loading dictionary items:', error);
            } finally {
                this.loading = false;
            }
        },

        async loadDirectory() {
            this.loading = true;
            const params = {
                'items-per-page': `${this.perPage}`,
                'currentPage': `${this.currentPage}`,
                'search-text': this.filter
            };

            let pages = '/pages?'

            for (const param in this.filterParams) {
                pages = '/filtered-pages?'
                const chosenFilter = this.filterParams[param]
                let trackBy
                for (let i = 0; i < this.activeDict.filters.length; i++) {
                    if (this.activeDict.filters[i].key === param) {
                        trackBy = this.activeDict.filters[i].trackBy
                    }
                }
                params[param] = chosenFilter[trackBy]
            }

            const queryString = new URLSearchParams(params).toString();

            try {
                const response = await fetch(`${this.activeDict.url}${this.activeDict.tableName}${pages}${queryString}`);
                const paginationList = await response.json();

                // Загрузка изначальных вариантов выбора
                for (const column of this.activeDict.cols) {
                    if (column.type === 'asyncSelect' || column.type === 'select-async-multiple') {
                        await this.loadSelects(column.key, '', column.selectsUrl)
                    }
                }

                this.activeDictItems = paginationList.items.map(item => {
                    item.open = !item.parent;
                    return item;
                });
                if (this.selectedDict === 'dict_ebk_doh') {
                    this.activeDictItems.forEach(it => {
                        it.budgetLevels = it.budgetLevels?.split(',')
                            .map(s => s.trim())
                            .filter(s => s !== '')
                            .map(Number) ?? null
                    })
                }
                if (this.selectedDict === 'appointment') {
                    this.activeDictItems.forEach(it => {
                        const moduleOption = this.asyncSelectOptions.module.find(option => option.code === it.module)
                        const operationOption = this.asyncSelectOptions.operation.find(option => option.code === it.operation)
                        it.module = moduleOption
                        it.operation = operationOption
                    })
                }

                this.select = true;
                this.itemCount = paginationList.itemCount
                this.currentPage = paginationList.page + 1
            } catch (error) {
                this.activeDictItems = []
                this.itemCount = 0
                console.error('An error occurred while loading dictionaries:', error);
            } finally {
                this.loading = false
            }
        },

        debouncedLoadDirectory: debounce(async function () {
            await this.loadDirectory()
        }, 600),

        async loadGuMainDirectory(regionCode, abpCode) {
            this.loading = true;
            const params = abpCode
            ? {
                'region-code': regionCode,
                'abp-code': abpCode
            }
            : {
                'region-code': regionCode
            };

            const queryString = new URLSearchParams(params).toString();

            try {
                const response = await fetch(`${this.activeDict.url}${this.activeDict.tableName}/region-abp?${queryString}`);
                const guItems = await response.json();
                let mainCode

                let isMoreThanOne = false
                let firstMainItem
                let abpCodeTemp = guItems[0].abp_owner
                guItems.map(item => {
                    // marking rows where multipe mains for one abp
                    if (abpCodeTemp !== item.abp_owner) {
                        abpCodeTemp = item.abp_owner
                        isMoreThanOne = false
                    }
                    if (item.main && isMoreThanOne) {
                        item._rowVariant = 'warning'
                        firstMainItem._rowVariant = 'warning'
                    } else if (item.main && !isMoreThanOne) {
                        isMoreThanOne = true
                        firstMainItem = item
                    }

                    mainCode = item.main ? item.code : mainCode
                    item.main = item.main ? 'O' : '-'
                    item.label = item.code + " " + item.name_ru
                    return item
                })
                this.selectedGuMain = mainCode
                this.guMain = mainCode
                this.guModalItems = guItems
                if (!this.ShowModal) {
                    this.allDictItems = guItems
                    this.filterGuMainItemsBy(this.selectedFilterOption)
                    this.itemCount = guItems.length
                }
            } catch (error) {
                this.activeDictItems = []
                this.itemCount = 0
                console.error('An error occurred while loading dictionaries:', error);
            } finally {
                this.loading = false
            }
        },

        async loadRegions() {
            this.loading = true;
            try {
                const response = await fetch('/api/dict/dict_budget_regions/order-by-code')

                if (response.ok) {
                    this.regions = await response.json();
                }

            } catch (error) {
                console.error('Error loading regions:', error);
            } finally {
                this.loading = false;
            }
        },

        async loadAbpItems(regionCode) {
            this.loading = true;
            try {
                const response = await fetch(`/api/dict/ebk_func_types/region-code/${regionCode}`)

                if (response.ok) {
                    const result = await response.json();
                    result.unshift({abp: '', name_ru: ''})
                    this.abpModalItems = result
                    if (!this.ShowModal)
                        this.abpItems = result
                }

            } catch (error) {
                console.error('Error loading abp items:', error);
            } finally {
                this.loading = false;
            }
        },

        downloadRep() {
            const dataToExcel = {
                url: this.activeDict.url,
                table_name: this.activeDict.tableName,
                name: this.activeDict.name
            };
            Ax(
                {
                    url: '/api-py/download_dict/',
                    method: 'POST',
                    responseType: 'blob',
                    data: dataToExcel
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', this.activeDict.name + '.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                }
            );
        },

        async save() {
            this.loading = true
            this.hasUserSub()
            try {
                const body = this.convertObjectTypeFields(JSON.parse(JSON.stringify(this.NewItem)))
                const response = await fetch(this.activeDict.url + this.activeDict.tableName, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                });
                if (response.ok) {
                    this.ShowModal = false;
                    this.makeToast('Элемент сохранен', 'Сообщение', 'success');
                    this.cleanItem();
                    await this.loadDirectory();
                } else {
                    let text = 'Извините, произошла ошибка на сервере или некорректно заполнены поля.'
                    const errorResponse = await response.json()
                    const key = errorResponse.key
                    const message = errorResponse?.args[0]?.value[0]
                    if (key) {
                        text = key
                    }
                    if (message) {
                        text = message
                    }
                    this.makeToast(text, 'Ошибка сохранения', 'danger');
                    throw new Error('Network response was not ok');
                }
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            } finally {
                this.loading = false
            }
        },

        async reassignGuMain() {
            this.loading = true;
            try {
                const requests = []

                this.allDictItems.forEach(item => {
                    if (item.main === 'O' && item.code !== this.selectedGuMain) {
                        requests.push(
                            fetch(`/api/dict/gu_main?gu-code=${item.code}`, {
                                method: 'DELETE'
                            })
                        )
                    } else if (item.main === 'O' && item.code === this.selectedGuMain) {
                    } else if (item.code === this.selectedGuMain) {
                        requests.push(
                        fetch(`/api/dict/gu_main?gu-code=${this.selectedGuMain}`, {
                        method: 'POST'
                        })
                        )
                    }
                })

                const responses = await Promise.all(requests);

                const [saveResponse] = responses;

                if (saveResponse.ok) {
                    this.makeToast('Элемент сохранен', 'Сообщение', 'success');
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса', error.toString());
            } finally {
                this.ShowModal = false;
                this.selectedGuMain = null
                this.guMain = null
                this.guModalItems = []
                this.cleanItem();
                this.selectedRegion = this.selectedModalRegion
                this.selectedAbp = this.selectedModalAbp
                this.setSelectedAbp(this.selectedModalAbp)
                this.selectedModalRegion = null
                this.selectedModalAbp = null
                this.loading = false;
            }
        },

        addItem: function () {
            this.cleanItem();
            this.setDefaults();
            this.ShowModal = true;
        },

        cleanItem: function () {
            this.NewItem = {};
        },

        setDefaults: function () {
            const tempItem = {}
            this.activeDict.cols.forEach(column => {
                const isNullOrUndefined = column.default == null
                if (!isNullOrUndefined) {
                    tempItem[column.key] = column.default
                }
            })
            this.NewItem = tempItem
        },

        millisToDate: function (millis) {
            if (typeof millis === 'string') {
                return millis
            }
            if (!millis) {
                return '';
            }
            const date = new Date(millis);
            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            if (month.toString().length === 1) {
                month = '0' + month;
            }
            let day = date.getDate();
            if (day.toString().length === 1) {
                day = '0' + day;
            }
            return year + '-' + month + '-' + day;
        },

        editItem: function () {
            if (this.selectedDictIsGuMain) {
                this.ShowModal = true
                return
            }

            if (this.NewItem.id) {
                this.ShowModal = true;
            } else {
                this.makeToast(
                    'Элемент справочника не выбран',
                    'Ошибка редактирования!'
                );
            }
        },

        async updateDictItem() {
            if (dictUpdateIncludeUsername.includes(this.selectedDict)) {
                this.hasUserSub()
            }
            const url = this.getUrl()
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            };
            const body = this.convertObjectTypeFields(JSON.parse(JSON.stringify(this.NewItem)))
            const response = await fetch(url, {
                headers,
                method: 'PUT',
                body: JSON.stringify(body)
            });
            if (response.ok) {
                this.ShowModal = false;
                this.cleanItem();
                await this.loadDirectory();
            } else {
                let text = 'Извините, произошла ошибка на сервере или некорректно заполнены поля.'
                const errorResponse = await response.json()
                const key = errorResponse.key
                const message = errorResponse?.args[0]?.value[0]
                if (key) {
                    text = key
                }
                if (message) {
                    text = message
                }
                this.makeToast(text, 'Ошибка сохранения', 'danger');
            }
        },

        getUrl() {
            if (this.NewItem.resource === 'dict') {
                return `${this.activeDict.url}dicts`
            }
            const customUrl = ['enstru']
            if (customUrl.includes(this.selectedDict)) {
                return `${this.activeDict.url}${this.activeDict.tableName}/edit-custom-names`
            }
            return `${this.activeDict.url}${this.activeDict.tableName}`
        },

        assignLabel(item, labels) {
            let label = ''
            labels.forEach((columnName, index) => {
                if (index === 0) {
                    label += item[columnName]
                } else {
                    label += (' - ' + item[columnName])
                }
            })
            item.label = label
            return item
        },

        saveOrUpdate: function () {
            if (typeof this.NewItem.id !== 'undefined') {
                this.updateDictItem();
            } else {
                this.save();
            }
        },

        convertObjectTypeFields: function (object) {
            // Специальные ковертирования
            const customConverter = ['enstru']
            if (customConverter.includes(this.selectedDict)) {
                return {
                    id: object.id,
                    customNameRu: object.customNameRu,
                    customNameKz: object.customNameKz
                }
            }

            // Обобщенное конвертирования
            const cols = this.activeDict.cols
            for (const col of cols) {
                switch (col.type) {
                    case 'asyncSelect': {
                        if (col.trackBy && object[col.key]) {
                            object[col.key] = object[col.key][col.trackBy]
                        }
                        break
                    }
                    case 'select-multiple': {
                        if (this.selectedDict === 'dict_ebk_doh') {
                            object[col.key] = object[col.key].sort().join(', ')
                        }
                        break
                    }
                    case 'select-async-multiple': {
                        object[col.key] = object[col.key]?.map(item => item[col.trackBy])
                        break
                    }
                    case 'switch': {
                        if (this.selectedDict === 'gu' && col.key === 'orgAttribute') {
                            if (object[col.key]) {
                                object[col.key] = '1'
                            } else {
                                object[col.key] = null
                            }
                        }
                        else if (!object[col.key]) {
                            object[col.key] = false
                        }
                        break
                    }
                    default:
                        break
                }
            }
            return object
        },

        hasUserSub: function () {
            const hasUserName = this.activeDict.cols?.find(col => col.key === 'userName') !== undefined;
            if (hasUserName) {
                this.NewItem.userName = this.userSub
            }
        },

        setSelectedModalRegionResettingAbp(value) {
            this.selectedModalAbp = null
            this.loadAbpItems(value.code)
        },

        setSelectedRegionResettingAbp(value) {
            this.selectedAbp = null
            this.loadAbpItems(value.code)
            this.loadGuMainDirectory(value.code, null)
        },

        setSelectedRegion(value) {
            this.loadAbpItems(value.code)
            this.loadGuMainDirectory(value.code, null)
        },

        setSelectedAbp(value) {
            this.loadGuMainDirectory(this.selectedRegion.code, value.abp)
        },
    }
};
</script>
<style scoped>
.input {
    width: 100%;
    max-width: 100%;
    height: 35px;
}

.tr {
    white-space: nowrap;
}

.modal-body {
    color: #000;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.float-left {
    color: rgb(218, 24, 40);
}

.search-nav {
    width: 700px;
}

.small-padding {
    padding: 5px;
}
</style>