
import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import {BIconSearch} from "bootstrap-vue";
import {
    TransfertGroup,
    applyFilters,
    dict_transfert_group,
    TransferData, ProjectFromBank, setTransferGroup, ProjectCheckerData
} from "@/modules/transfert/TransferTypes";


@Component({
    components: {BIconSearch}
})
export default class TransferAddModal extends Vue {
    @Prop({ required: true, default: () => [] }) readonly transferList!: ProjectCheckerData[];
    @Prop({ required: true, default: () => [] }) allTransfersList!: TransferData[];
    @Prop({ required: true }) regionFilter = "";
    @Prop({ required: true }) transferGroupFilter = ""

    async mounted() {
        await this.loadProjects();
    }
    
    updateAllTransfersList(newList: TransferData[]) {
        this.allTransfersList = newList;
        this.loadProjects();
    }

    @Watch("transferGroupFilter", { deep: true })
    onTransferGroupChange(newVal: string) {
        this.modalGroupFilter = newVal;
    }

    @Watch('modalGroupFilter')
    onModalGroupFilterChange(newVal: string | null) {
        if (newVal !== null) {
            this.modalProjectListFiltered.forEach(project => {
                if (!project.transfer_group_list.includes(newVal)) {
                    project.checked = false;
                }
            });
        }
    }

    modalGroupFilter = "01";
    dict_transfer_group = dict_transfert_group();
    modalProjectList: ProjectCheckerData[] = [];
    projectList: ProjectFromBank[] = [];
    modalProjectSearch = "";
    modal_fields = [
        {key: "checked", label: "", thStyle: {width: "15px"}},
        {
            key: "expense_code",
            label: "Код",
            sortable: true,
            sortByFormatted: true,
            thStyle: {width: "50px"},
            formatter: (value: any, key: any, item: TransferData) =>
                this.formatCode(value, key, item),
            sortCompare: (a: any, b: any, rowA: TransferData, rowB: TransferData) => {
                const codeA = this.formatCode(a, "expense_code", rowA);
                const codeB = this.formatCode(b, "expense_code", rowB);
                return codeA.localeCompare(codeB, undefined, { numeric: true, sensitivity: "base" });
            }
        },
        {
            key: "name", 
            label: "Наименование проекта", 
            sortable: true,
            sortByFormatted: true,
            formatter: (value: any, key: any, item: TransferData) =>
                this.formatName(value, key, item),
        },
    ];

    
    formatName(value: any, key: string, item: TransferData): string {
        if (!item?.project_from_bank?.code) {
            return "Нет наименования проекта";
        }
        return (
            this.modalProjectList.find(
                (value1) => value1.project_from_bank.code === item.project_from_bank.code
            )?.project_from_bank.name_ru || "Нет наименования проекта"
        );
    }

    formatCode(value: any, key: string, item: TransferData): string {
        if (!item?.project_from_bank?.code) {
            return "Нет кода в проектах";
        }
        return (
            this.modalProjectList.find(
                (value1) => value1.project_from_bank.code === item.project_from_bank.code
            )?.project_from_bank.code || "Нет кода в проектах"
        );
    }

    modalProjectAllChecked = false;

    modalProjectAllCheckedChange() {
        for (const mtd of this.modalProjectListFiltered) {
            mtd.checked = this.modalProjectAllChecked;
        }
    }
    uncheckSelected() {
        for (const modalTransferDatum of this.modalProjectListFiltered) {
            modalTransferDatum.checked = false;
        }
        this.modalProjectAllChecked = false;
    }

    get modalProjectListFiltered(): ProjectCheckerData[] {
        let  result = applyFilters(this.modalProjectList, this.modalGroupFilter);
        if (this.modalProjectSearch.length > 0) {
            const search = this.modalProjectSearch.toLowerCase();
                result = result.filter(
                    (value) =>
                        value.project_from_bank.code.toLowerCase().indexOf(search) > -1 ||
                        value.project_from_bank.name_ru.toLowerCase().indexOf(search) > -1
                );
            }
        const existingCodes = new Set(this.transferList.map(p => p.project_from_bank.code));
        result = result.filter(p => !existingCodes.has(p.project_from_bank.code));
        result = result.filter(p => {
            const { project_type, funding } = p.project_from_bank;
            // Целевые текущие трансферты и трансферты общего характера
            if (this.modalGroupFilter === '01' || this.modalGroupFilter === '03' || this.modalGroupFilter === '05') {
                return ['current', 'other'].includes(project_type) && funding !== "3";
            }
            // Трансферты по развитию
            if (this.modalGroupFilter === '02') {
                return ['development', 'imported'].includes(project_type) && funding !== "3";
            }
            // Кредиты
            if (this.modalGroupFilter === '04') {
                return funding === "3";
            }
            // Прочие
            if (this.modalGroupFilter === '06') {
                return funding !== "3";
            }
            return true;
        });
        result = result.filter(p => {
            const { budget_region } = p.project_from_bank;
            // Если регион проекта совпадает с фильтром
            if (budget_region === this.regionFilter) {
                return true;
            }
            // Если у проекта регион не указан (NULL) — доступен всем
            if (budget_region === null) {
                return true;
            }
            // Если проект уже добавлен районом, он должен отображаться в области с отметкой
            const foundInRegion = this.transferList.find(
                t => t.project_from_bank.code === p.project_from_bank.code
            );

            return !!foundInRegion;
        });
        result.sort((a, b) => a.project_from_bank.code.localeCompare(b.project_from_bank.code));
        return result;
    }

    transert_modal_close() {
        this.$bvModal.hide("transfer_add_modal");
    }

    transfer_modal_close_with_add() {
        this.modalProjectListFiltered.forEach(project => {
            if (project.checked) {
                project.transfer_group = this.modalGroupFilter;
            }
        });
        this.$emit("add-projects", this.modalProjectListFiltered);
        this.$bvModal.hide("transfer_add_modal");
    }

    private async loadProjects() {
        try {
            const response = await fetch("/api-py/get-transfert-projects");
            this.projectList = await response.json() as ProjectFromBank[];
            this.modalProjectList = this.modalProjectListDo();
            this.$emit("update-projects", this.projectList);
        } catch (error) {
            this.makeToast(
                "danger",
                "Ошибка загрузки проектов",
                error instanceof Error ? error.message : String(error)
            );
        }
    }

    modalProjectListDo(): ProjectCheckerData[] {
        const result = [];
        for (const transferDatum of this.projectList) {
            const mtd: ProjectCheckerData = {
                checked: false,
                transfer_group: this.transferGroupFilter !== null ? this.transferGroupFilter : "", 
                transfer_group_list: [],
                project_from_bank: transferDatum
            };
            const existingProject = this.allTransfersList.find(p => 
                p.project_from_bank.code?.trim() === transferDatum.code?.trim()
            );
            if (existingProject) {
                mtd.transfer_group_list = [existingProject.transfer_group]; 
            } else {
                setTransferGroup(mtd); 
            }
            result.push(mtd);
        }
        return result;
    }

    makeToast(variant: string, tostbody: string, title: string) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            autoHideDelay: 4000,
            solid: true,
        });
    }


    projectChecked(project: ProjectCheckerData) {
        if (!project.checked) return;

        if (this.modalGroupFilter !== null && !project.transfer_group_list.includes(this.modalGroupFilter)) {
            project.transfer_group_list.push(this.modalGroupFilter);
        }
    }
}
