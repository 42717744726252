
import {Vue, Component} from 'vue-property-decorator';
import moment from "moment/moment";

@Component({})
export default class ResetStatus extends Vue {
    id = 0;

    async reset_status() {
        try {
            const response = await fetch('/api-py/reset-budget-execution-alteration-request-status/' + this.id)
            const result = await response.json();
            this.makeToast("success", "Результат сохранения", result.message);
        } catch (error) {
            this.makeToast('danger', 'Ошибка сброса', error.toString());
        }
    }

    makeToast(variant: string, title: string, tostbody: string) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }
}
