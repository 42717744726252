
import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import {BIconSearch} from "bootstrap-vue";
import {KbkCode, TransferSources, ProjectFromBank, ProjectCheckerData} from "@/modules/transfert/TransferTypes";
import store from '@/services/store';
import { IAbpBase, ICurProg, IUrlParams, INullSubPrgs } from '@/modules/budget-request/components/js/budget-header-new-interfaces'


@Component({
    components: {BIconSearch}
})
export default class TransferKbkAddModal extends Vue {
    @Prop({ required: false, default: () => ({ abp: 0, prg: 0, ppr: 0 }) }) selectKbk!: KbkCode;
    kbkData: Record<string, any[]> = { abp: [], prg: [], ppr: [] };
    @Prop({ required: true }) project!: ProjectCheckerData;
    @Prop({ required: true }) isLead!: boolean;
    @Prop({ required: true }) userBudgetLevel!: string;
    @Prop({ required: true }) cur_year!: number;
    localSelectKbk: KbkCode = { abp: 0, prg: 0, ppr: 0 };
    @Prop({ required: true }) source!: TransferSources;

    created() {
        this.localSelectKbk = { ...this.selectKbk };
    }

    async mounted() {
        await this.initSelectedKbk();
    }

    private async initSelectedKbk() {
        this.curAbp = this.abpBase.find(item => item.abp === this.localSelectKbk.abp) || null;
        await this.loadProg();
        this.curProg = this.progBase.find(item => item.prg === this.localSelectKbk.prg) || null;
        await this.loadSubProg();
        this.curSubProg = this.subProgBase.find(item => item.ppr === this.localSelectKbk.ppr) || null;
    }

    @Watch("selectKbk", { immediate: true, deep: true })
    async onSelectKbkChange(newVal: KbkCode) {
        if (!newVal) return;
    
        await this.loadAbp();
        await this.loadProg();
        await this.loadSubProg();
        this.localSelectKbk = { ...newVal };
        this.initSelectedKbk();
    }
    @Watch("source", { deep: true })
    onBudgetLevelChange() {
        this.loadAbp();
    }
    @Watch("cur_year", { deep: true })
    onYearChange() {
        this.loadAbp();
    }

    @Watch("userBudgetLevel", { deep: true })
    onuserBudgetLevelChange() {
        this.loadAbp();
    }

    @Watch("localSelectKbk.abp", { deep: true })
    onAbpChange() {
        this.loadProg();
    }

    @Watch("localSelectKbk.prg", { deep: true })
    onPrgChange() {
        this.loadSubProg();
    }

    private get usrId(): string | null {
        if (!store.state.user.sub) { return null; }
        return store.state.user.sub;
    }
    
    private curAbp: IAbpBase | null = null;
    private abpBase: IAbpBase[] = [];
    private async loadAbp(){
        this.abpBase = [];
        if (this.usrId === null) return;
        let response: any[] = [];
        try {
        if (this.isLead) {
            const [respBL, respUM] = await Promise.all([
                this.loadAbpByUserBudgetLevel(),
                this.loadAbpByUM()
            ]);
            response = [...respBL, ...respUM];
        } else {
            response = await this.loadAbpByUM();
        }
    } catch (error) {
        this.makeToast('danger', 'Ошибка загрузки адм. программ', (error as Error).toString());
    }
    const budgetLevelMap: Record<string, number> = {
        '01': 1,
        '02': 1,
        '03': 2,
        '04': 3,
        '05': 4
    };

    const kbkBudgetLevel = budgetLevelMap[this.source.budget_level] || null;
    if (kbkBudgetLevel !== null) {
        response = response.filter(item => item.budget_level_id === kbkBudgetLevel);
    }
    response.sort((a, b) => a.abp - b.abp);
    this.abpBase = this.setIdArr(response, 'abp');
    }

    private async loadAbpByUserBudgetLevel() {
        let response: any = [];
        this.abpBase = [];
        if (this.usrId === null) { return; }
        if (response.length === 0) {
            try {
                response = await fetch(`/api-py/get-abp-by-user-budget-level/${this.userBudgetLevel}/${this.cur_year}`);
                return await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки адм. программ', (error as Error).toString());
                return [];
            }
        }
    }

    private async loadAbpByUM() {
        let response: any = [];
        this.abpBase = [];
        if (this.usrId === null) { return; }
        if (response.length === 0) {
            try {
                response = await fetch(`/api-py/get-abp-by-user-id/${store.state.user.sub}/${this.cur_year}`);
                return await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки адм. программ', (error as Error).toString());
                return [];
            }
        }
    }

    private curProg: ICurProg | null = null;
    private progBase: ICurProg[] = [];
    private async loadProg(): Promise<void> {
        let result: ICurProg[] = [];
        this.progBase.splice(0);
        const curAbp = this.localSelectKbk.abp;
        if (!this.project) return;
        if (result.length === 0) {
            if (!curAbp) return;
            
            try {
                const response = await fetch(`/api-py/get-programs-by-parent-type-actual/${curAbp}/4/${this.project.project_from_bank.begin_date}/${this.project.project_from_bank.end_date}/${this.firstDayOfYear}`);
                result = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки программ', (error as Error).toString());
            }
            this.progBase = this.setIdArr(result, 'prg');
        }
    }

    private curSubProg: ICurProg | null = null;
    private subProgBase: ICurProg[] = [];
    private async loadSubProg() {
        let result: ICurProg[] = [];
        this.subProgBase.splice(0);
        if (!this.project) return;
        const curAbp = this.localSelectKbk.abp;
        const curProg = this.localSelectKbk.prg;
        if (!curAbp || !curProg) return;
        
        await this.loadNullSubProg(curAbp, curProg);

        try {
            const response = await fetch(`/api-py/get-subprograms-by-abp-prg/${curAbp}/${curProg}/${this.project.project_from_bank.begin_date}/${this.project.project_from_bank.end_date}/${this.firstDayOfYear}`);
            result = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки подпрограмм', (error as Error).toString());
            result = [];
        }
        this.subProgBase = this.setIdArr(result, 'ppr');
        this.addNullProg(this.subProgBase);
        const validSubProgs = this.subProgBase.filter(item => item.ppr !== null);

        if (validSubProgs.length === 0) {
            this.curSubProg = null;
            this.localSelectKbk.ppr = null;
        } else if (validSubProgs.length === 1) {
            this.curSubProg = validSubProgs[0];
        } else {
            this.curSubProg = validSubProgs.reduce((min, item) => (item.ppr! < min.ppr! ? item : min), validSubProgs[0]);
        }
        if (this.curSubProg) {
            this.localSelectKbk.ppr = this.curSubProg.ppr;
        }
    }

    private nullSubPrograms: INullSubPrgs[] = []; 
    private nullSubProg = {
                abp: null,
                budget_level_id: null,
                develop_type: null,
                end_date: null,
                full_code: null,
                gr: null,
                id: -1,
                is_sequest: null,
                pgr: null,
                ppr: null,
                prg: null,
                short_name_kk: null,
                short_name_ru: null,
                transfer: null,
                type: null,
                name_kk: "0 - null", 
                name_ru: "0 - null", 
                lable_name_kk: "0 - null", 
                lable_name_ru: "0 - null"
            };
            
    // Загрузка пустых подпрограмм
    private async loadNullSubProg(curAbp: number, curProg: number) {
        try {
            const response = await fetch(`/api-py/get-null-subprograms-by-abp-prg/${curAbp}/${curProg}`);
            this.nullSubPrograms = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки подпрограмм', (error as Error).toString());
        }
    }
    
    private addNullProg(res: ICurProg[]) {
        if (this.isAnyNullSubProgActual) {
            res.unshift(this.nullSubProg);
        }
    }

    private get isAnyNullSubProgActual(): Boolean {
        let isAnyNullSubProgActual = false;
        
        if (!this.nullSubPrograms.length) return isAnyNullSubProgActual;
        const curStartDate = this.project.project_from_bank.begin_date;
        const curEndDate = this.project.project_from_bank.end_date;
        for (const nullSubProg of this.nullSubPrograms) {
            const isNullSubProgBegDateActual = (!nullSubProg.beg_date || nullSubProg.beg_date <= curEndDate!) 
            const isNullSubProgEndDateActual = (!nullSubProg.end_date || nullSubProg.end_date >= curStartDate!);
            isAnyNullSubProgActual = isNullSubProgBegDateActual && isNullSubProgEndDateActual;
            if (this.firstDayOfYear) {
                isAnyNullSubProgActual = (isNullSubProgBegDateActual && isNullSubProgEndDateActual) || nullSubProg.beg_date === this.firstDayOfYear;
            }
            if (isAnyNullSubProgActual) break;
        }
        return isAnyNullSubProgActual;
    }

    private get firstDayOfYear() {
        return this.cur_year.toString() + '-01-01';
    }

    makeToast(variant: string, tostbody: string, title: string) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            autoHideDelay: 4000,
            solid: true,
        });
    }

    transert_modal_close() {
        this.$bvModal.hide("transfer_kbk_add_modal");
    }

    transfer_modal_close_with_add() {
        this.$emit("add-kbk", this.localSelectKbk);
        this.$bvModal.hide("transfer_kbk_add_modal");
    }


    private setIdArr(arr: any[], codeName: string) {
        const result: any[] = [];
        for (const el of arr) {
            if (result.length > 0 && el[codeName] === result[result.length - 1][codeName]) {
                result[result.length - 1].idArr.push(el.id);
            } else {
                result.push(Object.assign({ idArr: [el.id] }, el));
            }
        }
        return result;
    }

    customLabelAbp(option: any) {
        if (!option) return "";
        return option.abp != null ? `${option.abp} - ${option.name_ru}` : option.name_ru;
    }

    customLabelPrg(option: any) {
        if (!option) return "";
        return option.prg != null ? `${option.prg} - ${option.name_ru}` : option.name_ru;
    }

    customLabelPpr(option: any) {
        if (!option) return "";
        return option.ppr != null ? `${option.ppr} - ${option.name_ru}` : option.name_ru;
    }

    private onAbpSelect(val: { abp: number }) {
        this.localSelectKbk.abp = val?.abp ?? 0;
    }

    private onPrgSelect(val: { prg: number }) {
        this.localSelectKbk.prg = val?.prg ?? 0;
    }

    private onPprSelect(val: { ppr: number }) {
        this.localSelectKbk.ppr = val?.ppr ?? 0;
    }

    get canAdd(): boolean {
        return !!this.curAbp && !!this.curProg && !!this.curSubProg;
    }
}
